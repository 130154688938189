$mobile-chart-height: $chart-height * 0.75;

// this is where we should be moving our chart layout formats to, so that
// each type of chart has a unique style
@import "charts/loading-status";

// all charts except configure charts (TODO this really needs to be refactored out)
.chart, .chart > ins > .chart.advertisement {
  text-align: left;
  display: inline-block;
  @media (max-width: $mobile) {
    display: block;
  }
  border: 0;
  margin: 0;
  margin-bottom: 1em * $whitespace;
  margin-right: 1em * $whitespace;
  @media (max-width: $mobile) {
    margin-bottom: 0.5em * $whitespace;
    margin-right: 0;
  }

  > header {
    display: block;
    position: relative;
    height: $chart-header-height;
    background: $chart-header;
    font-size: 90%;
    line-height: 18px;

    > h3 {
      margin: 0;
      padding: $chart-header-padding;
      padding-right: 0;
      font-size: 95%;
      font-weight: $font-weight-medium;
      display: inline-block;
      color: $black;

      a {
        color: $black;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    > small {
      font-size: 75%;
      color: transparentize($black, 0.5);
      padding-left: 3px;

      &.percent {
        font-weight: $font-weight-bold;

        &.positive {
          color: $green;
        }
        &.negative {
          color: $red;
        }
      }
    }

    > small.last-updated {
      cursor: pointer;
      position: absolute;
      top: $chart-header-padding + 1px;
      right: $chart-header-padding + $chart-header-icon + ($chart-header-padding / 2);
      display: inline-block;

      @media (max-width: $very-small) {
        display: none;
      }
    }

    > .notifications {
      position: absolute;
      top: $chart-header-padding;
      right: $chart-header-padding + $chart-header-icon + ($chart-header-padding / 2);
      width: $chart-header-icon;
      height: $chart-header-icon;
      display: none;
      background: url("/images/mail-outline.svg") 50% 40% no-repeat;
      background-size: $chart-header-height * 0.5;
      text-indent: 900em;
      overflow: hidden;
      opacity: 0.5;

      &[data-notifications=true] {
        background-image: url("/images/mail.svg");
      }

      &:hover {
        opacity: 1;
        transition: opacity 0.2s;
      }
    }

    > .options {
      position: absolute;
      top: $chart-header-padding;
      right: $chart-header-padding;
      width: $chart-header-icon;
      height: $chart-header-icon;
      display: inline-block;
      background: url("/images/gear-outline.svg") 50% 30% no-repeat;
      background-size: $chart-header-height * 0.5;
      text-indent: 900em;
      overflow: hidden;
      opacity: 0.5;

      &:hover {
        opacity: 1;
        transition: opacity 0.2s;
      }
    }
  }

  &.with-notifications {
    > header {
      > small.last-updated {
        right: $chart-header-padding + $chart-header-icon + $chart-header-icon + $chart-header-padding;
      }

      > .notifications {
        display: inline-block;
      }
    }
  }

  ins {
    text-decoration: none;
  }
}

.chart-target {
  overflow: hidden;
  display: block;
  background: $white;
  border: thin solid lighten($quiet-text, 30%);
  //border: thin solid lighten($app-navigation, 20%);

  > table.sortable {
    margin-left: auto;
    margin-right: auto;
    width: 92.5%;
    font-size: 90%;

    > thead > tr > th, > tbody > tr > td, > tbody > tr > th, > tfoot > tr > td {
      line-height: $line-height * 0.8; // reset line-height since we have less chart space
    }
  }

  &.portfolio-chart {
    table.table-chart {
      margin: 1em auto;
    }

    div.no-data {
      margin: 2em 1em;
      text-align: center;
      border: 0;
      color: $quiet-text;
      font-size: 90%;
    }
  }
}

// a general chart, use this when possible
.chart {
  $progress-bar-margin: 4px;
  $progress-text-height: 23px;
  $progress-bar-height: ($progress-bar-margin * 5) + $progress-text-height;

  > header > .progress, > aside.progress {
    z-index: 2;
    display: block;
    position: relative;
    top: $progress-bar-margin * 2;
    left: $progress-bar-margin * 2;
    width: $progress-text-height;
    height: $progress-text-height;
    padding: $progress-bar-margin;
    padding-left: $progress-bar-margin * 2;
    background: transparent;
    border: thin solid transparent;
    border-radius: $progress-bar-margin;
    box-shadow: none;

    transition: all 0.2s;

    > .status {
      text-align: left;
      font-size: 90%;

      .loading {
        width: 0;
        height: $progress-text-height;
        background-position-y: 40%;  // move the loading spinner to align with text
        overflow: hidden;
        display: block;
        text-decoration: none;    // turn off tooltip underline

        transition: all 0.2s;
      }
    }

    &:hover {
      width: 80%;
      background: $white;
      border-color: $quiet-border;
      box-shadow: 2px 2px 2px rgba(0,0,0,0.15);

      > .status .loading {
        width: 100%;
      }
    }
  }

  > header > .progress {
    top: $chart-height - $chart-header-height - $progress-bar-height;
    @media (max-width: $mobile) {
      top: $mobile-chart-height - $chart-header-height - $progress-bar-height;
    }
  }

  &.inline-chart {
    margin: 0;
  }

  &[data-format='loading-status'] {
    // reset chart styling
    .chart-target {
      background: transparent;
      border: 0;

      > div.loading {
        padding: 0;
      }
    }

    > aside.progress {
      position: absolute;
      top: $configure-chart-height - $progress-bar-height;

      &:hover {
        width: 55%;
        @media (max-width: $mobile) {
          width: 90%;
        }
      }
    }
  }
}

.chart-target {
  > div.loading {
    display: block;
    padding: 2em;
    font-size: 90%;
    color: $quiet-text;

    > span.loading {
      display: block;
      text-align: left;
    }
  }

  &[data-chart-type="table"], &[data-chart-type="transposed-table"] {
    overflow-x: hidden;
    overflow-y: auto;
  }

  // custom html tooltips within google charts
  div.google-visualization-tooltip {
    line-height: 1.3em;
    padding: 5px 0 0 5px;
    // to match the normal font on the rest of the chart
    font-family: Arial;
    font-size: 11px;
  }

  > table.table-chart {
    margin: 0;
  }
}

// chart sizing
section.chart-list, .preview-container {
  > .chart {
    width: $chart-width;
    height: $chart-height;
    @media (max-width: $mobile) {
      width: auto;
      height: $mobile-chart-height;
    }
  }

  .chart-target {
    height: $chart-height - $chart-header-height;
    @media (max-width: $mobile) {
      height: $mobile-chart-height - $chart-header-height;
    }
  }
}

section.fullpage-chart {
  text-align: center;
  background: $chart-outer-background;

  header {
    text-align: left;
  }

  > .chart {
    width: 100%;
    height: $mobile * $chart-height-ratio * 1.3;
    @media (max-width: $mobile * 1.5) {
      height: $mobile * $chart-height-ratio * 1.0;
    }
    @media (max-width: $mobile) {
      width: auto;
      height: $mobile * $chart-height-ratio * 0.75;
    }
    margin-right: 0;
  }

  .chart-target {
    height: ($mobile * $chart-height-ratio * 1.3) - $chart-header-height;
    @media (max-width: $mobile * 1.5) {
      height: ($mobile * $chart-height-ratio * 1.0) - $chart-header-height;
    }
    @media (max-width: $mobile) {
      height: ($mobile * $chart-height-ratio * 0.75) - $chart-header-height;
    }
  }
}

.add-chart-modal {
  .columns {
    a.button.chart {
      min-height: 4em;
      display: block;
      overflow: hidden;
      text-align: center;

      label {
        padding-top: 4em;
        padding-bottom: 0.25em;
        display: inline-block;
        text-align: center;
        cursor: pointer;
      }
      &.disabled label {
        cursor: initial;
      }
    }

    .column {
      margin-bottom: 0.5em;
    }
  }
}

.modal {
  .preview-container {
    display: block;

    .preview.chart {
      display: block;
      margin: 0 auto;

      .preview-text {
        color: $quiet-text;
        padding: 2em;
      }
    }
  }

  .advanced-options {
    &.accordian {
      > header {
        margin-top: 0.5em * $whitespace;
      }

      > aside {
        > table {
          @include accordian-area();
        }
      }
    }

    table.standard {
      &.indicators {
        margin: 0.5em;

        input[type=number] {
          min-width: 50px;
          width: 20%;
        }
      }
    }

    span.requires-upgrade {
      vertical-align: -25%;
    }

    .layout-options {
      @include accordian-area();

      .toggle-button {
        display: inline-block;
        margin: 0.25em * $whitespace;
      }
    }
  }
}

nav.breadcrumbs {
  font-size: 90%;
  padding-left: 0.25em * $whitespace;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin: 0;
      padding: 0;

      @include breadcrumb-icon($background-image: svg-load('images/breadcrumb-gray.svg'));
    }
  }
}

table.statistics {
  width: 100%;

  th, td {
    vertical-align: top;
  }

  th {
    text-align: left;
    padding-right: 0.5em;
    width: 15%;
  }
}

table.table-chart {
  table-layout: fixed;    // equal spacing for columns
}
