// For everything that is shared across everything

@import "core";
@import "variables";

@import "jquery-modal";
@import "select2";
@import "node_modules/qtip2/dist/jquery.qtip";  // until https://github.com/qTip2/qTip2/pull/834 is merged
@import "node_modules/@claviska/jquery-dropdown/jquery.dropdown.sass";  // until https://github.com/qTip2/qTip2/pull/834 is merged

// my own variables

html {
  font-weight: $font-weight;

  strong, b {
    font-weight: $font-weight-bold;
  }

  body {
    background: $background;
    color: $text;

    div.fullscreen-layout {
      background: $fullscreen-background;

      > div.logo {
        > a {
          background: url('/images/favicon.svg') no-repeat 0 0;
          background-size: contain;
          padding-top: 0.2em;
          padding-left: 2.5em;
          padding-right: 0.66em; // makes it a little bit better-aligned
          display: inline-block;
          color: mix($fullscreen-background, $fullscreen-text, 20%);
          text-decoration: none;

          &:hover {
            color: $fullscreen-text;
            text-decoration: underline;
            transition: color 0.3s;
          }
        }
      }

      @import "login";
    }
  }

  a {
    color: $link;
    // force all links, not just those with hrefs, to have some styles
    text-decoration: underline;
    cursor: pointer;

    &[name] {
      text-decoration: none;
      cursor: initial;
    }

    &:hover {
      transition: color 0.3s;
      color: $link-hover;
    }

    &[disabled] {
      @include tooltipize($quiet-text);
      color: $quiet-text;
      cursor: initial;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  span.extra-info, th.extra-info, td.extra-info, a.extra-info, label.extra-info {
    @media (max-width: $mobile) {
      display: none;
    }
  }

  span.mobile-only, th.mobile-only, td.mobile-only, a.mobile-only, label.mobile-only {
    display: none;
    @media (max-width: $mobile) {
      display: inline;
    }
  }

  span.no-wrap, div.no-wrap {
    white-space: nowrap;
  }

  figure {
    > figcaption {
      width: 80%;
      margin: (1em * $whitespace) auto;
      text-align: center;
      vertical-align: top;

      @media (max-width: $mobile) {
        width: 90%;
      }
    }
  }
}

@import "cookies";
@import "historical";
@import "currencies";
@import "countries";
@import "explorers";
@import "charts";
@import "site-updates";
@import "footer";
@import "support";
@import "user-purchases";
@import "oauth2";
@import "forms";
@import "exchange-pairs";
@import "wallets";
@import "terms";
@import "top-navigation";
@import "filters";
@import "gonative";
@import "app-stores";
