html {
  @import "buttons";

  @mixin standard-text-area() {
    margin: 0;
    padding: 2px 5px 0;
    min-width: 200px;
    @media (max-width: $mobile) {
      min-width: 133px;
    }
    border-radius: 0;
    border: 1px solid lighten($gray, 10%);

    background-color: $background;
    color: $text;
    box-sizing: border-box;  // no, select2! you don't get to mess up our box sizing model
    box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);

    &.right {
      text-align: right;
    }

    &:focus {
      border-color: $blue;
      transition: border-color 0.1s;
    }

    &:invalid {
      background-color: mix($background, $red, 80%);
      color: mix($text, $red, 40%);
      border-color: mix(lighten($gray, 10%), $red, 40%);
      transition: all 0.1s;
    }

    &.currency {
      text-align: right;
      background-position: 5px 50%;
      padding-left: 5px;
    }

    &.percent {
      max-width: 100px;
    }
  }

  input[type=text], input[type=password], input[type=email], input[type=url], input[type=number], input[type=date], input[type=time], select.dropdown, .select2-container .select2-selection {
    @include standard-text-area();

    font-family: $font-family;
    font-size: 100%;
    font-weight: $font-weight;
    line-height: $line-height * 1.1;
    height: $line-height;
  }

  textarea {
    @include standard-text-area();

    font-family: $font-family-monospace;
    font-size: 100%;
    font-weight: $font-weight;
    line-height: $line-height * 1;

    &.variable {
      font-family: $font-family;
    }
  }

  // input[type=email] {
  //   background-image: url("/images/mail-outline.svg");
  //   background-position: 95% 50%;
  //   background-repeat: no-repeat;
  //   background-size: 16px;
  // }

  input[type=file] {
    margin: 1em * $whitespace 0;
    padding: 1em * $whitespace;
    min-width: 200px;
    @media (max-width: $mobile) {
      min-width: 100px;
    }
    border-radius: 5px;
    border: 1px solid lighten($gray, 10%);
    font-family: $font-family;
    font-size: 100%;
    font-weight: $font-weight;
    color: $text;
    box-sizing: border-box;  // no, select2! you don't get to mess up our box sizing model
    box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
  }

  input[type=checkbox] {
    font-size: 150%;
  }

  textarea {
    &.big {
      margin: 0.5em * $whitespace 0;
      width: 90%;
      height: 10em;
    }
  }

  .column {
    input[type=text], input[type=password], input[type=email], input[type=url], input[type=number], input[type=date], input[type=time], select.dropdown, .select2-container .select2-selection {
      min-width: 133px;
    }
  }

  span.toggle-button {
    input[type=checkbox] {
      visibility: hidden;
      position: absolute;     // don't let it interfere with our page layout

      + label {
        color: $text;
      }

      &:disabled + label {
        color: $cancel-button;
        background: $background;
        cursor: initial;
        &:hover {
          background: $background;
        }
      }
    }

    @mixin toggle-button-label($color) {
      input[type=checkbox]:checked + label {
        @include buttonize($color, $white);
      }
    }

    @include toggle-button-label($primary-button);

    &.cancel {
      @include toggle-button-label($cancel-button);
    }

    &.ok {
      @include toggle-button-label($ok-button);
    }

    &.slider {
      @include slider-toggle-button(15px, 48px);
    }
  }

  div.field_with_errors {
    display: inline-block;
    color: darken($red, 20%);

    input[type=text], input[type=password], input[type=number], input[type=email], input[type=url], input[type=date], input[type=time], select.dropdown, .select2-container .select2-selection {
      background: lighten($red, 30%);
      border-color: darken($red, 10%);

      &:focus {
        border-color: $blue;
        transition: border-color 0.1s;
      }
    }
  }

  table.standard {
    margin-top: 1em * $whitespace;
    margin-bottom: 1em * $whitespace;
    width: 95%;

    @media (max-width: $mobile) {
      width: 100%;
    }

    input[type=text], input[type=url], select.dropdown, .select2-container .select2-selection {
      width: 80%;
    }
    input[type=password] {
      width: 50%;
    }

    // dropdowns in big tables can be super wide
    select.dropdown, .select2-container .select2-selection {
      min-width: 300px;
      @media (max-width: $mobile) {
        min-width: 200px;
      }
      @media (max-width: $very-small) {
        min-width: 133px;
      }
    }

    .optional {
      color: $quiet-text;
    }

    .example {
      color: $quiet-text;
      display: inline-block;
      margin-bottom: 0.5em * $whitespace;

      [data-tooltip].info {
        margin: 0;
        vertical-align: middle;
      }
    }

    .info[data-tooltip] {
      vertical-align: -0.9em;
      margin: 0.5em * $whitespace;
    }
  }

  // dropdowns in modals need to not be as wide
  .modal, .column {
    table.standard {
      select.dropdown, .select2-container .select2-selection {
        min-width: 200px;
        @media (max-width: $very-small) {
          min-width: 133px;
        }
      }
    }
  }

  .container > form {
    > footer {
      text-align: right;
    }
  }

  form table.standard {
    > tbody > tr {
      > th {
        text-align: left;
        vertical-align: top;
        padding-top: 0.35em;  // to align with the baseline of text fields, but still support multiline tds
        padding-right: 0.5em;
        width: 20%;
        @media (max-width: $mobile) {
          padding-right: 0;
          overflow: hidden;
          font-size: 80%;
          > label {
            white-space: no-wrap;
          }
        }
      }
      > td, > th {
        min-width: 7.5em * $whitespace;
        @media (max-width: $mobile) {
          min-width: 5em * $whitespace;
        }
      }
    }
  }

  // rather than taking the existing select2 and butchering its styles,
  // you could also set theme to blank and create a new style from scratch
  .select2-container {
    text-align: left;
    font-size: 90%;
    line-height: $line-height * 0.75;

    .select2-dropdown, .select2-selection__rendered {
      .select-template {
        text-align: left;

        > .title {
          font-size: 80%;
          color: transparentize($text, 0.5);
        }
      }
    }

    .select2-selection--single .select2-selection__rendered {
      padding: 0;
      color: $text;
    }

    .select2-search__field {
      font-family: $font-family;
      font-weight: $font-weight;
      font-size: 90%;
    }

    .select2-results__message {
      font-style: italic;
    }

    .select2-results {
      box-shadow: 0 4px 4px rgba(0,0,0,0.3);
    }

    &.select2-dropdown--below {
      border-radius: 0;
    }

    .loading-results {
      display: none;
    }

    .select2-results__option--highlighted[aria-selected] {
      background: $container-header;
      color: $container-header-text;

      span.text {
        color: $container-header-text;
      }

      span.title {
        color: transparentize($container-header-text, 0.5);
      }
    }

    .select2-results__option {
      min-height: 1em;
    }

    &.select2-container--open .select2-selection {
      border-color: $blue;
      transition: border-color 0.1s;
    }
  }

  // adjust the <select> to take up the same whitespace before
  // select2 applies its styles
  select.dropdown {
    vertical-align: middle;
    display: inline-block;
    padding: 2px 5px 0;

    &[disabled] {
      background-color: #eee;
    }
  }
}

