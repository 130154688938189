html {
  $modal-padding: 15px;
  $modal-radius: 8px;

  .blocker {
    // regardless of what we put on the site, we should never be THIS
    // desparate to override z-index higher than this!
    z-index: 1000;

    padding: 20px; // default
    @media (max-width: $mobile) {
      padding: 10px;
      padding-top: 20px;
    }
    @media (max-width: $very-small) {
      padding: 5px;
      padding-top: 20px;
    }
  }

  .modal {
    padding: $modal-padding * $whitespace;
    background: $background;
    color: $text;

    width: 90%;
    max-width: $mobile;
    margin-bottom: 20px;

    @media (max-width: $mobile) {
      width: 100%;
    }

    a.close-modal {
      top: 5px;
      right: 2px;
      background: none;
      text-indent: 0;
      font-size: 40px;
      line-height: 40px;
      color: transparentize($text, 0.5);
      text-decoration: none;

      &:hover {
        transition: color 0.3s;
        color: transparentize($text, 0.2);
      }

      &.with-header {
        top: 5px - $modal-padding;
        color: transparentize($container-header-text, 0.5);
        &:hover {
          color: transparentize($container-header-text, 0.2);
        }
      }
    }

    > header, > form > header, > div.target > header, > div.target > form > header {
      position: relative;
      top: -$modal-padding * $whitespace;
      background: $container-header;
      color: $container-header-text;
      margin: (-$modal-padding * $whitespace);
      margin-bottom: 0;
      padding: (10px * $whitespace) ($modal-padding * $whitespace) (5px * $whitespace);
      border-radius: $modal-radius $modal-radius 0 0;
      border-bottom: $container-border;
      box-shadow: 0 3px 3px rgba(0,0,0,0.1);

      h1, h2, h3, h4, h5, h6 {
        margin: 0;
        padding: 0;
        font-size: 100%;

        a {
          color: $container-header-text;
        }
      }
    }

    > footer, > form > footer, > div.target > footer, > div.target > form > footer {
      position: relative;
      bottom: -$modal-padding * $whitespace;
      clear: both;
      background: $container-footer;
      color: $container-footer-text;
      margin: (-$modal-padding * $whitespace);
      margin-top: 0;
      padding: (10px * $whitespace);
      border-radius: 0 0 $modal-radius $modal-radius;
      border-top: $container-border;
      box-shadow: inset 0 3px 3px rgba(0,0,0,0.1);
      text-align: right;

      button, a.button, input[type=submit], input[type=reset] {
        line-height: $line-height * 0.85;
      }

      > aside.help {
        position: absolute;
        left: 10px * $whitespace;
        bottom: 10px * $whitespace;
        // stop help from rendering on top of buttons
        z-index: 2;

        @media (max-width: $very-small) {
          display: none; // not enough to display the text, sadly
        }
      }
    }

    aside.help {
      margin-top: 1em * $whitespace;
      font-size: 80%;
      color: $quiet-text;
      clear: both;

      a {
        color: $quiet-text;
        &:hover {
          color: darken($quiet-text, 20%);
          transition: color 0.3s;
        }
      }
    }
  }
}
