$closable-icon-size: 1.25em;

.closable {
  position: relative; // reset for absolute position below

  > .closable-button {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: $closable-icon-size;
    height: $closable-icon-size;
    background: svg-load('images/cross-gray-transparent-background.svg') 0 0 no-repeat;
    background-size: $closable-icon-size;
    opacity: 0.5;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
}
