.site-update {
  border-top: thin solid mix($quiet-text, $background);
  box-shadow: inset 2px 2px 4px rgba(0,0,0,0.075);
  padding: 1em;

  &:first-of-type {
    margin-top: 1em;
  }

  &:nth-of-type(odd) {
    background: #eee;
  }

  h2 {
    font-size: 120%;
    font-weight: $font-weight-medium;
    margin-top: 0.1em;
    color: $indigo;

    a.external::after {
      margin-left: 7px;
    }
  }

  img[border] {
    margin: 0 auto;
    border: thin solid mix($text, $background, 20%);
    display: block;
    max-width: 100%;
    box-shadow: 2px 2px 4px rgba(0,0,0,0.1);
  }
}

.controller-site_updates {
  .content {
    footer {
      margin: 2em * $whitespace;
      text-align: right;
    }
  }
}
