/**
 * For fullscreen modal dialogs
 */

html {
  body {
    div.fullscreen-layout {
      display: table-cell;
      width: 100%;
      height: 100%;
      min-width: 100%;
      min-height: 100%;

      padding: 10px * $whitespace;
      @media (max-width: $mobile) {
        padding: 5px * $whitespace;
        padding-top: 10px * $whitespace;
      }

      > nav {
        display: none;
      }

      > div.logo {
        text-align: center;
        margin: 0 auto;
        margin-top: 4em * $whitespace;
        text-shadow: 1px 1px 1px rgba(0,0,0,0.3);

        @media (max-width: $mobile) {
          margin-top: 0.5em * $whitespace;
        }

        @media (max-height: $mobile / 2) {
          margin-top: 0.5em * $whitespace;
        }
      }

      > section.fullscreen {
        margin-top: 2em * $whitespace;
      }

      section.fullscreen {
        width: 50%;
        min-width: ($mobile * 0.9);
        max-width: ($mobile * 1.1);
        margin: auto auto;
        margin-top: 1em * $whitespace;
        border: 1px inset $line;
        background: $fullscreen-dialog-background;
        padding: (10px * $whitespace);
        box-shadow: 2px 2px 20px rgba(0,0,0,0.5);
        border-radius: 10px;

        &.bright {
          background: $background;
        }

        @media (max-width: $mobile) {
          min-width: 80%;
          margin-top: 0.75em * $whitespace;
        }

        > header, > footer, > form > header {
          clear: both;
        }

        > header, > form > header {
          margin: (5px * $whitespace) 0;
          border-bottom: 2px groove $line;

          h1, h2, h3, h4, h5, h6 {
            margin: 0;
            padding: 0;
            font-size: 100%;
          }

          &.warning {
            position: relative;
            background: linear-gradient(darken($red, 20%), darken($red, 30%));
            color: $container-header-text;
            margin: (-10px * $whitespace);
            margin-bottom: (10px * $whitespace);
            padding: (10px * $whitespace);
            border-bottom: $container-border;
            box-shadow: 0 3px 3px rgba(0,0,0,0.1);
            border-radius: 10px 10px 0 0;

            h1 {
              font-size: 150%;
              margin: 0.5em 0.25em 0.25em 0.25em;
              font-weight: $font-weight-medium;
            }
          }
        }

        > section {
          overflow: auto; // https://www.w3schools.com/css/css_float.asp
        }

        > footer, > form > footer {
          border-top: 2px groove $line;
          padding-top: 10px * $whitespace;
          text-align: right;

          button, .button {
            vertical-align: middle;
          }
        }

        &.delete-account {
          table.standard {
            max-width: ($mobile);
            margin: 0 auto;

            th {
              width: 40%;
            }

            input[type=text] {
              max-width: 300px;
            }

            textarea {
              width: 90%;
              min-width: 300px;
              min-height: 150px;
            }
          }
        }
      }
    }
  }
}
