// note that a page simply being rendered on a GoNative device doesn't mean
// it's a mobile page; it could also be a tablet, ipad etc
html.gonative {
  div.fullpage-layout {
    > section {
      @media (max-width: $mobile) {
        padding: 10px 10px;
      }
      @media (max-width: $very-small) {
        padding: 5px 5px;
      }
    }

    // charts need to be fullscreen
    &.controller-portfolios.action-show > section,
    &.controller-portfolios.action-public > section {
      @media (max-width: $mobile) {
        padding: 10px 0;
      }
      @media (max-width: $very-small) {
        padding: 5px 0;
      }
    }

    section.chart-list > .chart {
      // make charts full-width on mobile
      @media (max-width: $mobile) {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
      }
    }

    section.chart-list .chart-target {
      // make charts even more full-width on mobile
      @media (max-width: $mobile) {
        border-left: 0;
        border-right: 0;
      }
    }
  }

  div.fullscreen-layout {
    > .logo {
      display: none;
    }

    padding-top: 1em; // to replace logo

    @media (max-width: $very-small) {
      padding-top: 0.25em; // not enough space
    }
  }

  nav.breadcrumbs {
    display: none; // users should instead be using native navigation
  }

  img.comodo {
    display: none; // it doesn't look very nice
  }

  div.layout > div > div.header {
    // hide *everything*; all navigation should be native
    display: none;
  }

  div.public-layout, div.fullpage-layout {
    > footer {
      display: none;
    }
  }

  div.layout > div.public-layout > div.content {
    box-shadow: none;

    header.top:first-of-type {
      h1 {
        margin: 0.75em * $whitespace;
      }
    }
  }

  // gonative landing page
  div.fullscreen-layout.controller-welcome.action-gonative {
    padding: (1.25em * $whitespace) (0.75em * $whitespace);

    section.fullscreen.gonative {
      margin-top: 2em * $whitespace;

      @media (max-width: $mobile) {
        min-width: 90%;
      }

      > section.description {
        text-align: center;
        margin: (1.25em * $whitespace) (1em * $whitespace);
        overflow: initial;
      }

      > section.action {
        // same as the footer style
        font-size: 90%;
        color: mix($public-footer-text, $public-footer, 50%);

        padding-top: 0.25em * $whitespace;
        padding-bottom: 0.75em * $whitespace;
        text-align: center;

        display: block;
        height: 3em * $whitespace;

        button, .button {
          vertical-align: middle;
          font-size: 110%;
          margin-left: .5em;
          margin-right: .5em;

          @media (max-width: $very-small) {
            margin-left: 0.25em;
            margin-right: 0.25em;
          }

          &.primary {
            // keep the login/signup buttons the same width, so that
            // 'or' and the following buttons align up nicely
            width: 3em;
          }
        }
      }

      footer {
        margin-top: 1em * $whitespace;

        text-align: center;
        font-size: 90%;

        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            display: inline-block;
            margin: 0.25em 1em;

            a {
              color: mix($text, $quiet-text);
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
