.accordian {
  header {
    text-align: left;
    cursor: pointer;
    display: block;
    padding-top: 2px;

    &:hover {
      text-decoration: underline;
    }

    $container-header: $quiet-text;
    $sorter-weight: 1px;

    &:after {
      //display: none; // hide until we're actually sorting - so it doesn't render on top of text on mobile view
      position: relative;
      content: '';
      display: inline-block;
      border: solid $container-header;
      border-width: 0 $sorter-weight $sorter-weight 0;
      padding: 3px;
      margin-left: 7px;

      top: -5px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }

    &.active:after {
      top: 0;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }
  }

  // the panel
  aside, &.opened.loaded aside {
    // for animations
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    box-sizing: border-box;

    // hacky hack to stop weird Firefox layout jumping
    margin-bottom: 1px;
    &.active {
      margin-bottom: 0;
    }
  }

  &.opened {
    aside {
      max-height: initial;
    }
  }
}

.column > .accordian {
  vertical-align: top;
}
