.idea {
  background-image: svg-load('images/idea.svg');
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: 0 40%;
  padding-left: 16px + 8px;
  margin-left: -4px;
}

.button > span.api {
  background-image: svg-load('images/gear-outline.svg');
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: 0 40%;
  padding-left: 16px + 8px;
  margin-left: -4px;
}
