.chart[data-format='loading-status'] {
  width: 100%;
  height: $default-progress-bar-height;
  margin: 0;

  // initial 'loading', before the chart has actually sent AJAX
  > span.loading {
    display: none;
  }

  @media (max-width: $mobile) {
    display: none;
  }

  &.chart-ready {
    ul.summaries {
      display: none;
    }
  }

  ul.summaries {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  aside.progress {
    display: none !important;
  }

  > div.chart-target {
    display: none;
  }
}
