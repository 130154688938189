table.sortable {
  width: 100%;
  padding: 0;
  border-collapse: collapse;
  border-radius: 0;

  > thead > tr > th {
    text-align: center;
    font-weight: $font-weight-medium;
    vertical-align: middle;
    height: 1.75em;
    overflow: hidden; // don't let the ^/v mess up mobile views
    position: relative;

    &:not([data-sorter=false]):hover {
      transition: background 0.3s;
      background: darken($container-header, 5%);
    }
  }

  &.subtle {
    > thead {
      font-size: 90%;
      padding: (5px * $whitespace);
    }
  }

  > thead > tr > th, > tbody > tr > td, > tfoot > tr > td {
    line-height: $line-height * 1.2;

    &.checkbox {
      width: 20px;
      max-width: 20px;
    }
  }

  > tbody > tr > td, > tfoot > tr > td {
    text-align: left;

    &.number, &.numbers, &.date, &.dates, &.balance, &.balances {
      text-align: right;
    }

    &.percent {
      text-align: left;
    }

    &.actions {
      text-align: center;

      a.button {
        min-width: 50px;
        float: right;
        margin: 0.5em;

        + a.button {
          margin-top: 0;
        }
      }
    }

    &.empty {
      text-align: center;
      font-size: 90%;
      color: $quiet-text;

      &:hover {
        background: inherit;
      }
    }

    &.sum {
      font-weight: $font-weight-medium;
    }

    span.percent {
      font-size: 90%;
      font-weight: $font-weight-bold;

      &:before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-right: 6px;
        background-size: cover;
        vertical-align: 0%;
      }

      &.increase {
        color: $green;
        &:before {
          background-image: url('/images/arrow-up.svg');
        }
      }
      &.decrease {
        color: $red;
        &:before {
          background-image: url('/images/arrow-down.svg');
        }
      }
      &.no-change {
        color: $gray;
        &:before {
          display: none;
        }
      }
    }
  }

  > thead > tr > th, > tbody > tr > th, > tbody > tr > td, > tfoot > tr > td {
    border: thin solid rgba(0,0,0,0.15);
    border-radius: 0;
    padding: (3px * $whitespace) (7px * $whitespace);

    > div.tablesorter-header-inner {
      $sorter-weight: 2px;

      cursor: pointer;
      &:after {
        display: none; // hide until we're actually sorting - so it doesn't render on top of text on mobile view
        position: absolute;
        top: 4px + (7px * $whitespace);
        right: 4px + (5px * $whitespace);
        content: '';
        border: solid $container-header;
        border-width: 0 $sorter-weight $sorter-weight 0;
        padding: 3px;
      }
    }

    &.tablesorter-headerAsc > div.tablesorter-header-inner:after {
      display: inline-block;
      border-color: $container-header-text;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
      top: 4px + (9px * $whitespace);
    }
    &.tablesorter-headerDesc > div.tablesorter-header-inner:after {
      display: inline-block;
      border-color: $container-header-text;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }

    // turn off cursor for rows that can't be sorted
    &[data-sorter=false] {
      > div.tablesorter-header-inner {
        cursor: initial;
      }
    }
  }


  > thead > tr > th, > tfoot > tr > td {
    padding-top: 5px * $whitespace;
  }

  > tbody {
    > tr {
      $zebra-background: mix($background, $container-footer, 75%);

      background: $background;
      color: $text;

      &.selected {
        background: mix($background, $amber, 90%);
      }

      &:nth-of-type(even) {
        background: $zebra-background;

        &.selected {
          background: mix($zebra-background, $amber, 83%);
        }
      }

      &:hover {
        transition: background 0.3s;
        background: darken($background, 5%);

        &.selected {
          background: darken(mix($background, $amber, 90%), 7.5%);
        }

        &:nth-of-type(even) {
          background: darken($zebra-background, 2.5%);

          &.selected {
            background: darken(mix($zebra-background, $amber, 83%), 5%);
          }
        }
      }
    }
  }

  > tbody, > tfoot {
    > tr {
      &.credit > td, > td.credit {
        color: $green;
      }
      &.debit > td, > td.debit {
        color: $red;
      }
    }
  }

  > caption {
    margin: 0.5em * $whitespace;
    font-weight: $font-weight-medium;
    color: $quiet-text;
    caption-side: top;
  }

  + aside.pagination {
    border-top: $container-border-subtle;
    margin: (-10px * $whitespace) 0 (10px * $whitespace);
  }
}
