.explorer[data-explorer] {
  padding-left: 16px + 4px;
  min-height: 16px;
  background-repeat: no-repeat;
  background-position: left 30%;
  background-size: 16px;
  background-image: url('/images/currencies/generic.svg');

  > small {
    color: transparentize($text, 0.5);
  }
}

.explorer[data-explorer$='bithomp.com'] {
  background-image: url('/images/explorers/bithomp.png');
}

.explorer[data-explorer$='bitpay.com'] {
  background-image: url('/images/explorers/insight.png');
}

.explorer[data-explorer$='blockchain.info'],
.explorer[data-explorer$='blockchain.com'] {
  background-image: url('/images/explorers/blockchain.info.svg');
}

.explorer[data-explorer$='blockexplorer.com'] {
  background-image: url('/images/explorers/blockexplorer.png');
}

.explorer[data-explorer$='blocktrail.com'] {
  background-image: url('/images/explorers/blocktrail.png');
}

.explorer[data-explorer$='cardanoexplorer.com'] {
  background-image: url('/images/explorers/cardano-explorer.svg');
}

.explorer[data-explorer$='chainz.cryptoid.info'] {
  background-image: url('/images/explorers/cryptoid.png');
}

.explorer[data-explorer$='chain.so'] {
  background-image: url('/images/explorers/sochain.png');
}

.explorer[data-explorer$='etherscan.io'] {
  background-image: url('/images/explorers/etherscan.io.png');
}

.explorer[data-explorer$='prohashing.com'] {
  background-image: url('/images/explorers/prohashing.png');
}
