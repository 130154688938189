// Commented out all of the Coderay syntax that we don't use, to optimise the filesize.
// Alternatively we could use this .css file as input into Coderay, but that seems unnecessary

// .CodeRay {
//   background-color: #efefef;
//   font-family: Monaco, "Courier New", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", monospace;
//   color: #000;
//   margin-bottom: 14px;
// }

// .CodeRay pre {
//   margin: 0px;
//   padding: 1em;
// }

// div.CodeRay { }
// span.CodeRay { white-space: pre; border: 0px; padding: 2px }

// table.CodeRay { border-collapse: collapse; width: 100%; padding: 2px }
// table.CodeRay td {
//   padding: 1em 0.5em;
//   vertical-align: top;
// }

// .CodeRay .line-numbers, .CodeRay .no {
//   background-color: #ECECEC;
//   color: #AAA;
//   text-align: right;
// }

// .CodeRay .line-numbers a {
//   color: #AAA;
// }

// .CodeRay .line-numbers tt { font-weight: bold }
// .CodeRay .line-numbers .highlighted { color: red }
// .CodeRay .line { display: block; float: left; width: 100%; }
// .CodeRay span.line-numbers { padding: 0px 4px }
// .CodeRay .code { width: 100% }

// ol.CodeRay { font-size: 10pt }
// ol.CodeRay li { white-space: pre }

// .CodeRay .code pre { overflow: auto }
// .CodeRay .debug { color:white ! important; background:blue ! important; }

// .CodeRay .annotation { color:#007 }
// .CodeRay .attribute-name { color:#f08 }
// .CodeRay .attribute-value { color:#700 }
// .CodeRay .binary { color:#509; font-weight:bold }
.CodeRay .comment  { color:#998; font-style: italic;}
// .CodeRay .char { color:#04D }
// .CodeRay .char .content { color:#04D }
// .CodeRay .char .delimiter { color:#039 }
// .CodeRay .class { color:#458; font-weight:bold }
// .CodeRay .complex { color:#A08; font-weight:bold }
// .CodeRay .constant { color:teal; }
// .CodeRay .color { color:#0A0 }
// .CodeRay .class-variable { color:#369 }
// .CodeRay .decorator { color:#B0B; }
// .CodeRay .definition { color:#099; font-weight:bold }
// .CodeRay .directive { color:#088; font-weight:bold }
// .CodeRay .delimiter { color:black }
// .CodeRay .doc { color:#970 }
// .CodeRay .doctype { color:#34b }
// .CodeRay .doc-string { color:#D42; font-weight:bold }
// .CodeRay .escape  { color:#666; font-weight:bold }
// .CodeRay .entity { color:#800; font-weight:bold }
// .CodeRay .error { color:#F00; background-color:#FAA }
// .CodeRay .exception { color:#C00; font-weight:bold }
// .CodeRay .filename { color:#099; }
// .CodeRay .function { color:#900; font-weight:bold }
// .CodeRay .global-variable { color:teal; font-weight:bold }
// .CodeRay .hex { color:#058; font-weight:bold }
.CodeRay .integer  { color: $lighter-blue; }
// .CodeRay .include { color:#B44; font-weight:bold }
// .CodeRay .inline { color: black }
// .CodeRay .inline .inline { background: #ccc }
// .CodeRay .inline .inline .inline { background: #bbb }
// .CodeRay .inline .inline-delimiter { color: #D14; }
// .CodeRay .inline-delimiter { color: #D14; }
// .CodeRay .important { color:#f00; }
// .CodeRay .interpreted { color:#B2B; font-weight:bold }
// .CodeRay .instance-variable { color:teal }
// .CodeRay .label { color:#970; font-weight:bold }
// .CodeRay .local-variable { color:#963 }
// .CodeRay .octal { color:#40E; font-weight:bold }
// .CodeRay .operator { }
.CodeRay .predefined-constant { color: $lighter-blue; }
// .CodeRay .predefined { color:#369; font-weight:bold }
// .CodeRay .preprocessor { color:#579; }
// .CodeRay .pseudo-class { color:#00C; font-weight:bold }
// .CodeRay .predefined-type { color:#074; font-weight:bold }
// .CodeRay .reserved, .keyword  { color:#000; font-weight:bold }

.CodeRay .key { color: $dark-blue; }
.CodeRay .key .delimiter { color: #606; }
.CodeRay .key .char { color: #80f; }
.CodeRay .value { color: $lighter-blue; }

// .CodeRay .regexp { background-color:#fff0ff }
// .CodeRay .regexp .content { color:#808 }
// .CodeRay .regexp .delimiter { color:#404 }
// .CodeRay .regexp .modifier { color:#C2C }
// .CodeRay .regexp .function  { color:#404; font-weight: bold }

.CodeRay .string { color: $primary-green; }
.CodeRay .string .string { }
// .CodeRay .string .string .string { background-color:#ffd0d0 }
.CodeRay .string .content { color: $primary-green; }
.CodeRay .string .char { color: $primary-green; }
.CodeRay .string .delimiter { color: $primary-green; }

// .CodeRay .shell { color:#D14 }
// .CodeRay .shell .content { }
// .CodeRay .shell .delimiter { color:#D14 }

// .CodeRay .symbol { color:#990073 }
// .CodeRay .symbol .content { color:#A60 }
// .CodeRay .symbol .delimiter { color:#630 }

// .CodeRay .tag { color:#070 }
// .CodeRay .tag-special { color:#D70; font-weight:bold }
// .CodeRay .type { color:#339; font-weight:bold }
// .CodeRay .variable  { color:#036 }

// .CodeRay .insert { background: #afa; }
// .CodeRay .delete { background: #faa; }
// .CodeRay .change { color: #aaf; background: #007; }
// .CodeRay .head { color: #f8f; background: #505 }

// .CodeRay .insert .insert { color: #080; font-weight:bold }
// .CodeRay .delete .delete { color: #800; font-weight:bold }
// .CodeRay .change .change { color: #66f; }
// .CodeRay .head .head { color: #f4f; }
