ul.days-selector {
  list-style: none;
  margin: 0;
  margin-bottom: 0.25em;
  padding: 0.5em;

  display: inline-block;
  background: $background;
  border: thin solid $quiet-border;

  > li {
    display: inline-block;
    margin: 0;
    padding: 0 1em;

    @media (max-width: $mobile) {
      padding: 0 0.25em;
    }

    + li {
      border-left: thin solid $quiet-border;

      @media (max-width: $mobile) {
        border-left: 0;
      }
    }

    > a {
      &:hover, &:active {
        color: $text;
      }
    }

    &.selected {
      a {
        color: $text;
        font-weight: $font-weight-bold;
        text-decoration: none;
      }
    }

    &.requires-upgrade {
      > a {
        background: url("/images/lock-gray.svg") 2px 40% no-repeat;
        background-size: 8px;
        padding-left: 8px + 8px;

        color: $quiet-text;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}

div.exchange-pair {
  header.top {
    .exchange-link {
      margin-right: 10px;
      color: $text;
    }
  }

  table.todays-rates, table.statistics {
    padding: 0.5em;

    tr {
      th {
        width: 25%;

        @media (max-width: $very-small) {
          width: 40%;
        }
      }
    }
  }

  table.todays-rates {
    border: thin solid $quiet-border;
  }

  @media (max-width: $mobile) {
    table.statistics {
      tr.exchange, tr.base, tr.quote {
        display: none;
      }
    }

    table.todays-rates {
      display: none; // not enough space
    }
  }
}
