aside.pagination {
  background: mix($background, $quiet-text, 90%);
  color: mix($text, $quiet-text, 50%);

  border-top: $container-border-subtle;
  box-shadow: inset 0 3px 3px rgba(0,0,0,0.05);
  padding-bottom: (5px * $whitespace);

  margin: (-10px * $whitespace) 0 (10px * $whitespace);

  text-align: center;
  padding: 15px * $whitespace 5px * $whitespace;

  > ul {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      display: inline-block;
      margin: 0 3px;

      > a {
        display: inline-block;
        text-decoration: none;
        padding: 4px 8px 2px;
        font-weight: $font-weight-medium;

        &:hover {
          text-decoration: underline;
        }
      }

      > a.prev, > a.next {
        border: thin solid $link;
        background: $background;
      }

      @media (max-width: $mobile) {
        > a.prev {
          display: none;
        }
      }
    }
  }
}
