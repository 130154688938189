// form extensions that don't make sense in something like core/

.select2-dropdown, .select2-selection__rendered {
  .select-template.requires-upgrade {
    > span {
      background: url("/images/lock-gray.svg") 2px 40% no-repeat;
      background-size: 8px;
      display: inline-block;
      padding-left: 8px + 8px;

      > small {
        color: $quiet-text;
      }
    }
  }
}

form {
  table.standard {
    span.requires-upgrade {
      background: url("/images/lock-gray.svg") 2px 40% no-repeat;
      background-size: 8px;
      padding: 2px;
      padding-left: 8px + 8px;
      font-size: 90%;

      color: $quiet-text;
      vertical-align: text-bottom;
    }
  }
}
