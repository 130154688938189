@mixin active-button-padding($top, $left, $button-press: $button-press) {
  padding: $top $left;

  &:active {
    padding: ($top + $button-press) ($left - $button-press) ($top - $button-press) ($left + $button-press);
    transition: padding 0.03s;

    &.disabled, &:disabled, &[disabled] {
      padding: $top $left;
    }
  }
}

@mixin buttonize-square($background: $background, $color: $normal-button, $border-radius: $button-top) {
  background: $background;
  border: 2px solid $color;
  border-radius: $border-radius;
  font-family: $alt-font-family;
  font-weight: $font-weight-medium;
  color: $color;

  &:hover {
    color: $background;
    background: $color;
    transition: all 0.1s;
  }

  &:active {
    border-color: darken($color, 20%);
  }

  small {
    color: mix($background, $color);
  }

  &.disabled, &[disabled] {
    background: none;
    cursor: initial;
    border-color: darken(transparentize($background, 0.25), 30%);
    color: darken(transparentize($background, 0.25), 30%);

    transition: all 0.1s;  // would be nice to put this in the parent button, but since the hover removes the gradient, it ends up flashing on Firefox

    &:hover {
      background: none;
    }
    small {
      color: transparentize($color, 0.5);
    }
  }
}

@mixin standard-buttons($button-top, $button-side, $button-radius, $font-family, $line-height) {
  button, input[type=submit], input[type=reset], a.button, span.toggle-button > label {
    font-family: $font-family;
    border: 0;
    border-radius: $button-radius;
    cursor: pointer;
    vertical-align: top;
    box-shadow: 0 0 2px rgba(255,255,255,0.2);
    text-decoration: none;
    font-size: 90%;
    font-weight: $font-weight;
    display: inline-block;
    line-height: $line-height;
    box-sizing: content-box;    // use a consistent box sizing model please

    // stop Safari from messing up our custom styles
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    @include active-button-padding($button-top, $button-side);

    &.small {
      @include active-button-padding($button-top / 2, $button-side / 2);
    }

    &.wide {
      @include active-button-padding($button-top, $button-side * 2);
    }

    &.wide-on-fullscreen {
      @include active-button-padding($button-top, $button-side * 2);

      @media (max-width: $public-width) {
        @include active-button-padding($button-top, $button-side);
      }
    }

    small {
      font-size: 80%;
    }

    &:hover {
      transition: background 0.3s;
    }

    @include buttonize($normal-button, $normal-button-text);

    &.cancel {
      @include buttonize($cancel-button, $cancel-button-text);
    }

    &.ok, &.save, &.new {
      @include buttonize($ok-button, $ok-button-text);
    }

    &.primary, &.selected {
      @include buttonize($primary-button, $primary-button-text);
    }

    &.delete {
      @include buttonize($delete-button, $delete-button-text);
    }

    &.square {
      @include buttonize-square($background, $normal-button-text);

      &.ok, &.save, &.new {
        @include buttonize-square($background, $ok-button);
      }

      &.primary, &.selected {
        @include buttonize-square($background, $primary-button);
      }
    }

    &.outline {
      @include buttonize-square($background, $normal-button-text, $button-radius);

      &.ok, &.save, &.new {
        @include buttonize-square($background, $ok-button, $button-radius);

        &.sky-blue {
          @include buttonize-square($sky-blue, $ok-button, $button-radius);
        }
      }
    }

    // displays the button as a big button
    // and the button can contain an image
    &.option {
      // for those without images
      min-height: 2.25em + $option-button-image-size;
      min-width: 4em;
      overflow: hidden;

      // for background images
      text-align: center;
      background-position: 50% 30%;
      background-size: $option-button-image-size;
      background-repeat: no-repeat;

      &:hover, &[disabled], &[disabled]:hover {
        background-position: 50% 30%;
        background-size: $option-button-image-size;
        background-repeat: no-repeat;
      }

      &:hover {
        &.primary {
          background-color: darken($primary-button, 5%);
        }
      }

      &[disabled] {
        background-color: $background;
        &:hover {
          background-color: $background;
        }
      }

      label {
        padding-top: 1.25em + $option-button-image-size;
        padding-bottom: 0.25em;
        display: inline-block;
        text-align: center;
        cursor: pointer;
      }
    }

    > label {
      cursor: pointer;
    }

    & + button, & + a.button, & + input[type=submit], & + input[type=reset] {
      margin-left: 0.5em;
    }

    // radio buttons are special - we don't want the padding
    // in the <a> (because that would capture clicks without
    // triggering the <label> click). we want the padding in
    // the <label>.
    &.radio-button {
      padding: 0;

      label {
        display: inline-block;
        padding: $button-top $button-side;

        &:active {
          padding: ($button-top + $button-press) ($button-side - $button-press) ($button-top - $button-press) ($button-side + $button-press);
        }
      }

      & + a.button.radio-button {
        margin-left: 0;
      }
    }
  }
}

// new design
@include standard-buttons(
  $button-top: $button-top,
  $button-side: $button-side,
  $button-radius: $button-radius,
  $font-family: $font-family,
  $line-height: $line-height
);

// old design
body.legacy {
  $line-height: 1.4em;
  $button-top: 10px;
  $button-side: 20px;
  $button-radius: 3px;

  @include standard-buttons(
    $button-top: $button-top,
    $button-side: $button-side,
    $button-radius: $button-radius,
    $font-family: $font-family,
    $line-height: $line-height
  );

  // fix slider button
  span.toggle-button {
    &.slider {
      @include slider-toggle-button(12px, 36px);
    }
  }
}
