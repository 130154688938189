section.columns {
  width: 100%;
  clear: both;

  &:after {
    content: '';
    display: block;
    clear: both;
  }

  section.column {
    margin: 0;
    padding: 0;
    float: left;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    @media (max-width: $mobile) {
      width: 100%;
      & + section.column {
        margin-top: 10px * $whitespace;
      }
    }

    h1, h2, h3, h4, h5, h6 {
      margin: 0;
      margin-bottom: 3px * $whitespace;
      padding: 0;
      font-size: 100%;
    }

    // inside columns, we want the buttons to be 100% wide
    .button.option, input[type=submit].option {
      display: block;
      margin: 0.5em 0.5em;
    }
  }

  @media (max-width: $mobile) {
    // turn off colums, go back to an inline-list type
    &.inline {
      > section.column {
        display: inline-block;
        width: initial !important;
        float: initial;

        + section.column {
          margin-top: initial;
        }

        button, .button {
          padding: 5px 10px;
        }
      }
    }
  }

  &.columns-2 {
    > section.column {
      width: 50%;

      // Force columns to always display in twos, even if the inner contents
      // (I'm looking at you <figure>) messes up the rendering engine
      &:nth-of-type(2) {
        &::after {
          content: '';
          display: inline-block;
          clear: both;
        }
      }
    }
  }

  &.columns-1-or-1 {
    > section.column {
      width: 49%;

      &.column-or {
        width: 2%;
        text-align: center;
        color: $quiet-text;
        font-size: 90%;

        @media (max-width: $mobile) {
          width: 100%;
        }
      }

      @media (max-width: $mobile) {
        width: 100%;
        & + section.column {
          margin-top: 0;
        }
      }
    }
  }

  &.columns-3 {
    > section.column {
      width: 33.3333%;
    }
  }

  &.columns-4 {
    > section.column {
      width: 25%;
    }
  }

  &.columns-5 {
    > section.column {
      width: 20%;
    }
  }

  &.columns-2-1 {
    > section.column {
      width: 66.6667%;

      + section.column {
        width: 33.3333%;
        @media (max-width: $mobile) {
          width: 100%;
        }
      }
    }
  }

  &.columns-1-2 {
    > section.column {
      width: 33.3333%;

      + section.column {
        width: 66.6667%;
        @media (max-width: $mobile) {
          width: 100%;
        }
      }
    }
  }

  &.columns-4-3 {
    > section.column {
      width: (100% / 7) * 4;

      + section.column {
        width: (100% / 7) * 3;
        @media (max-width: $mobile) {
          width: 100%;
        }
      }
    }
  }

  &.columns-5-3 {
    > section.column {
      width: (100% / 8) * 5;

      + section.column {
        width: (100% / 8) * 3;
        @media (max-width: $mobile) {
          width: 100%;
        }
      }
    }
  }

  &.columns-9-1 {
    > section.column {
      width: (100% / 10) * 9;

      + section.column {
        width: (100% / 10) * 1;
        @media (max-width: $mobile) {
          width: 100%;
        }
      }
    }
  }

  // implement collapsing
  @media (max-width: $mobile) {
    &:not(.inline) {
      &.columns-2, &.columns-3, &.columns-4, &.columns-5,
      &.columns-2-1, &.columns-1-2,
      &.columns-4-3, &.columns-5-3,
      &.columns-9-1 {
        > section.column {
          width: 100%;
          & + section.column {
            margin-top: 10px * $whitespace;
          }
        }

        &.collapses-to-50 {
          > section.column {
            width: 50%;
            & + section.column {
              margin-top: 0;
            }
          }
        }
      }
    }
  }

  // implement collapsing
  @media (max-width: $public-width) {
    &.collapse-public:not(.inline) {
      &.columns-2, &.columns-3, &.columns-4, &.columns-5,
      &.columns-2-1, &.columns-1-2,
      &.columns-4-3, &.columns-5-3,
      &.columns-9-1 {
        > section.column {
          width: 100%;
          & + section.column {
            margin-top: 10px * $whitespace;
          }
        }

        &.collapses-to-50 {
          > section.column {
            width: 50%;
            & + section.column {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}
