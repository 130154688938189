@import "variables";

$line: rgba(0,0,0,0.2);

html {
  font-family: $font-family;
  font-size: $font-size;
  line-height: $line-height;
  background: white;
}

html {
  display: table;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

  body {
    display: table-cell;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;

    .layout {
      min-width: 100%;
      min-height: 100%;
      width: 100%;
      height: 100%;
      display: table;
      position: absolute;
      margin: 0;
      padding: 0;
    }

    div.standard-layout {
      padding: 15px * $whitespace;
      @media (max-width: $mobile) {
        padding: 7.5px * $whitespace;
      }
    }
  }
}

// Using target=_blank is a security risk! Don't use this unless
// you set all the relative tags as necessary.
// See: https://mathiasbynens.github.io/rel-noopener/
/*
/* a[target=_blank], */
a.external {
  &::after {
    content: '';
    background: red;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 2px 2px 2px 4px;
    vertical-align: top;
    background: url('/images/popup.svg') top left no-repeat;
    background-size: contain;
  }
}

.flash {
  animation-name: flash-animation;
  animation-duration: 0.5s;
}

span.qr-code {
  display: inline-block;
}

@keyframes flash-animation {
  from { background: $flash-colour; }
  to { background: default; }
}

div.quiet, span.quiet {
  color: $quiet-text;
}

@import "core/accordian";
@import "core/action-bar";
@import "core/carousel";
@import "core/closable";
@import "core/coderay";
@import "core/columns";
@import "core/containers";
@import "core/dropdowns";
@import "core/dynamic-ajax";
@import "core/filetypes";
@import "core/forms";
@import "core/insertable";
@import "core/lightbox";
@import "core/loading";
@import "core/modal";
@import "core/notices";
@import "core/pagination";
@import "core/progress";
@import "core/swappable";
@import "core/tooltips";

@import "core/fullscreen";
@import "core/fullpage";
