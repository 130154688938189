$term-number-width: 4%;

section.welcome {
  h1 {
    font-size: 140%;
  }
  h2 {
    font-size: 120%;
  }
  h3 {
    font-size: 100%;
  }

  &.terms, &.privacy, &.security {
    ol {
      list-style-type: lower-roman;
      li {
        padding-left: 0.5em;
        margin-right: 2em;
      }
    }
  }

  &.terms {
    > h2 {
      color: $indigo;
      > b {
        display: inline-block;
        width: $term-number-width;
        min-width: 32px;
      }
    }

    > p {
      > b {
        font-weight: $font-weight;
        vertical-align: top;
        display: inline-block;
        min-height: 1em;
        width: $term-number-width;
        min-width: 32px;
      }
      > span {
        vertical-align: top;
        display: inline-block;
        width: (100% - $term-number-width - 1%);
        @media (max-width: $mobile) {
          width: (100% - $term-number-width - 6%);
        }
      }
    }

    > ol, > ul {
      padding-left: $term-number-width * 2;
      @media (max-width: $mobile) {
        padding-left: $term-number-width * 1.75;
      }
      > li {
        width: (100% - $term-number-width - 1%);
        @media (max-width: $mobile) {
          width: (100% - $term-number-width - 6%);
        }
      }
      li {
        margin-bottom: 5px;
      }
      ol, ul {
        margin-top: 5px;
        padding-left: $term-number-width * 0.75;
      }
    }

    > ol {
      list-style-type: lower-alpha;
    }
  }
}

@mixin fancy-terms-alignment-of-numbers-for-mobile() {
  section.welcome {
    &.terms {
      > p {
        > span {
          width: (100% - $term-number-width - 6%);
        }
      }

      > ol, > ul {
        padding-left: $term-number-width * 1.75;
        > li {
          width: (100% - $term-number-width - 6%);
        }
      }
    }
  }
}

// mobile and in-modal
.modal {
  @include fancy-terms-alignment-of-numbers-for-mobile();
  h1 {
    margin-top: 0;
  }
}
@media (max-width: $mobile) {
  @include fancy-terms-alignment-of-numbers-for-mobile();
}
