.swappable {
  > .swap-target {
    &.hidden {
      input[type=submit], button, .button {
        // disable any submit buttons on the swapped element
        display: none;
        visibility: hidden;
        position: absolute;
        width: 0;
        height: 0;
      }
    }
  }
}

// You can use some animated magic, but since we have to use absolutely-
// positioned child elements, we can't automatically fill the height
// of the parent, so you will need to add this information manually
// with the 'height:' CSS attribute.
.swappable.animated {
  display: block;
  position: relative;
  overflow: hidden !important;
  height: 10em;
  width: 100%;

  > .swap-target {
    display: block !important;
    overflow: hidden;

    left: 0;
    top: 0;
    visibility: visible;
    position: absolute;
    transition: 0.2s;
    width: 100%;

    &.hidden {
      visibility: hidden;
      left: 500px;
      transition: 0.2s;
    }
  }
}
