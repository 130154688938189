body {
  $footer-text-color: mix($public-footer-text, $public-footer, 33%);

  div.public-layout, div.fullpage-layout, div.public-2018-layout {
    > footer {
      text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
      box-shadow: 0 -5px 10px rgba(0,0,0,0.15);
      font-size: $font-size * 0.95;
      line-height: $line-height * 0.95;
      color: $footer-text-color;

      @media (max-width: $mobile) {
        font-size: $font-size * 0.85;
        line-height: $line-height * 0.85;
      }

      a {
        color: $public-footer-text;
        opacity: 0.75;

        &:hover, &:active {
          opacity: 1;
          transition: opacity 0.3s;
        }

        &.twitter, &.facebook {
          padding-left: 1.4em;
          background-size: 1em;
          background-position: 0 50%;
          background-repeat: no-repeat;
        }

        &.twitter {
          background-image: url('/images/twitter.svg');
        }

        &.facebook {
          background-image: url('/images/facebook.svg');
        }
      }

      [data-tooltip] {
        text-decoration: none;
      }

      > nav {
        background: $lighter-dark-indigo;

        padding: 2em * $public-whitespace;
        padding-bottom: 1em * $public-whitespace;
        @media (max-width: $public-width) {
          padding: 1em * $public-whitespace;
        }

        > section.content {
          max-width: $public-width;
          margin: 0 auto;

          > ul {
            list-style: none;
            display: inline-block;
            margin: 0;
            padding: 0;

            > li {
              display: inline-block;
              vertical-align: top;
              margin: 0;
              margin-right: 0.5em * $public-whitespace;
              padding: 0;
            }
          }

          .column {
            h4 {
              font-size: 130%;
              font-family: $alt-font-family;
              font-weight: $alt-font-weight-medium;
              line-height: $line-height * 1.25;

              padding: 0;
              padding-bottom: 0.5em * $whitespace;
              margin-bottom: 1em * $whitespace;
              margin-right: 3em * $whitespace;
              border-bottom: thin solid $public-footer-text;

              @media (max-width: $mobile) {
                margin-bottom: 0em * $whitespace;
                margin-right: 0;
                border-bottom: 0;

                &.pct50 {
                  margin-top: 2em * $whitespace;
                }
              }

              > a {
                opacity: 1;
              }
            }

            > ul {
              list-style: none;
              margin: 0;
              padding: 0;

              > li {
                display: block;
                font-size: 90%;
                line-height: $line-height * 1;

                a {
                  text-decoration: none;

                  &:hover, &:active {
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }

        p.copyright {
          font-size: 75%;
          line-height: $line-height * 0.6;
          margin-top: 1.5em * $public-whitespace;
          margin-bottom: 0.5em * $public-whitespace;

          a {
            color: $footer-text-color;
            text-decoration: none;
            opacity: 1;

            &:hover {
              color: mix($footer-text-color, $white, 80%);
              text-decoration: underline;
            }
          }
        }

        .app-store-buttons {
          @media (max-width: $public-width) {
            margin-top: 1em * $whitespace;
          }

          a {
            margin-bottom: 0.5em * $whitespace;
          }
        }
      }

      > footer {
        padding: 1em * $public-whitespace 2em * $public-whitespace;
        @media (max-width: $mobile) {
          padding: 0.5em * $public-whitespace;
        }

        @include max-public-header-width();

        > ul {
          list-style: none;
          display: inline-block;
          margin: 0;
          padding: 0;

          > li {
            display: inline-block;
            vertical-align: top;
            margin: 0;
            margin-right: 1em * $public-whitespace;
            padding: 0;
            font-size: 90%;

            > a {
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }

          @media (max-width: $mobile) {
            text-align: center;

            > li {
              margin: 0.5em * $public-whitespace;
            }
          }
        }
      }
    }
  }
}
