$file-type-size: 18px;

[data-file-type] {
  color: $text;
  padding-left: 16px + 6px;
  min-height: 16px;
  background-repeat: no-repeat;
  background-position: left 30%;
  background-size: 16px;
  background-image: url('/images/file-types/file.svg');

  &[data-file-type=pdf] {
    background-image: url('/images/file-types/pdf.svg');
  }

  &[data-file-type=html] {
    background-image: url('/images/file-types/html.svg');
  }

  &[data-file-type=csv] {
    background-image: url('/images/file-types/csv.svg');
  }
}
