.user-purchase {
  .purchase-selection {
    width: 50%;
    margin: 0 auto;

    th {
      width: 25%;
      text-align: left;

      a {
        color: $text;
        text-decoration: none;
      }
    }
  }

  .discount, .surcharge {
    margin-top: 0.5em * $whitespace;
    font-size: 90%;
  }

  .discount {
    color: $green;
  }
  .surcharge {
    color: $quiet-text;
  }

  .payment-method {
    aside.help {
      text-align: center;
      margin-left: 1.5em * $whitespace;
      margin-right: 1.5em * $whitespace;
    }
  }

  section.columns {
    section.column {
      a.button.option, input[type=submit].option {
        background-position: 50% 1.25em;

        &.plan {
          background-image: url('/images/plan.svg');
        }

        &.term {
          background-image: url('/images/month.svg');

          &.yearly {
            background-image: url('/images/year.svg');
          }
        }

        &.coinpayments {
          background-image: url('/images/payment-methods/coinpayments.png');
          background-size: $option-button-image-size * 0.75;
          background-position: 50% 0.5em;
        }

        &.stripe {
          background-image: url('/images/payment-methods/stripe.svg');
        }

        &.submit {
          padding-top: 1em + $option-button-image-size;
          padding-bottom: 0.25em;
          margin: 0 auto;
          min-height: 3em;
        }
      }
    }

    &.stripe {
      form {
        text-align: center;

        button.stripe-button-el {
          padding: 0;
          border: thin solid $background;
          transition: border 0.3s;

          &:hover {
            border: thin solid $ok-button;
          }

          > span {
            font-family: $font-family;
            font-weight: $font-weight;
            font-size: 130%;
            padding: 0.25em 1.5em;
          }
        }
      }
    }
  }

  table.invoice {
    width: 90%;
    border-collapse: collapse;
    margin: (1.25em * $whitespace) auto;

    tbody {
      border-top: thin solid $gray;
      border-bottom: thin solid $gray;
    }

    th, td {
      padding: (0.5em * $whitespace) 0;
      vertical-align: top;

      &.item {
        width: 60%;
        text-align: left;

        .quiet {
          font-size: 90%;
        }
      }

      &.quantity {
        width: 10%;
        text-align: right;
      }

      &.balance {
        width: 30%;
        text-align: right;
      }
    }
  }

  aside.invoice.details, aside.payment.details {
    text-align: right;
    width: 90%;
    margin: 0 auto;

    h2 {
      margin-top: 1.25em * $whitespace;
      font-size: 120%;
      margin-bottom: 0.25em * $whitespace;
    }

    h3 {
      font-size: 100%;
      color: $quiet-text;
      font-weight: $font-weight;
      margin-top: 0;
    }
  }

  .dynamic-template > .status {
    min-width: 10em * $whitespace;
    margin: (1.5em * $whitespace) auto;
    padding-top: 0.25em * $whitespace;
    text-align: center;
    font-size: 120%;
    color: $quiet-text;

    > span.complete {
      @include loading-span('/images/tick.svg', $green, 0.9, 0% 30%);
    }

    > span.cancelled, > span.failed {
      @include loading-span('/images/cross.svg', $red, 0.9, 0% 30%);
    }
  }

  .automatically-renew, .security-information {
    display: block;
    margin: 1em auto 2em;
    max-width: $mobile * 0.7;

    span.quiet {
      display: block;
      margin-top: 1em;
      font-size: 90%;
      line-height: $line-height * 0.9;
    }
  }

  section.promo {
    text-align: center;
    font-size: 90%;
    margin: (1em * $whitespace) auto;
  }
}
