[data-tooltip], [data-permanent-tooltip] {
  @include tooltipize($text)
  cursor: help;

  &.why {
    display: inline-block;
    margin-left: .5em;
    font-size: 90%;
    color: transparentize($link, 0.3);
  }

  &.info, &.icon {
    $info-size: 0.9em;

    display: inline-block;
    margin-left: $info-size / 4;
    text-indent: 900em;
    overflow: hidden;
    width: $info-size;
    height: $info-size;
    background: svg-load('images/question.svg') 50% 50% no-repeat;
    background-size: $info-size;
    opacity: 0.5;
    vertical-align: -10%;

    &:hover {
      opacity: 1;
      transition: opacity 0.2s;
    }

    &.requires-upgrade {
      background-image: svg-load('images/lock-gray.svg');
      background-size: $info-size * 0.8;
    }

    &.warning {
      background-image: svg-load('images/warning.svg');
      background-size: $info-size * 0.9;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }

    &.critical, &.error {
      background-image: svg-load('images/cross.svg');
      opacity: 0.75;

      &:hover {
        opacity: 1;
      }
    }

    &.delete {
      background-image: svg-load('images/delete.svg');
      opacity: 0.75;

      &:hover {
        opacity: 1;
      }
    }
  }
}

a {
  [data-tooltip], [data-permanent-tooltip], &[data-tooltip], &[data-permanent-tooltip] {
    cursor: pointer;
  }
}

// turn off underline on gonative, so it looks less web-by
html.gonative {
  [data-tooltip], [data-permanent-tooltip] {
    text-decoration: none;
  }
}
