.modal.lightbox {
  width: auto;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;

  > img {
    margin: auto auto;
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
  }

  > a.close-modal {
    background: transparentize($white, 0.5);
    padding-left: 10px;
    padding-bottom: 5px;
    padding-top: 2px;
    border-radius: 5px;
    box-shadow: 1px 1px 1px rgba(0,0,0,0.1);
    top: 8px;
    right: 8px;

    &:hover {
      transition: all 0.1s;
      top: 7px;
      right: 9px;
      box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
    }
  }
}

[data-lightbox] img {
  cursor: pointer;
}
