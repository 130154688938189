// normal dropdowns
a.caret {
  text-decoration: none;
  white-space: nowrap;

  &:after {
    content: '\25BC';
    font-size: 70%;
    padding-left: 0;
    vertical-align: 5%;
  }

  &.jq-dropdown-open {
    &:after {
      content: '\25B2';
    }
  }
}

body {
  .jq-dropdown .jq-dropdown-menu, .jq-dropdown .jq-dropdown-panel {
    border: 1px solid #999;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.5);
    text-align: left; //default

    li a {
      color: $text;
      text-decoration: none;
      font-size: $font-size * 0.8;
      line-height: $line-height * 0.65;
    }

    a.disabled, a[disabled] {
      color: #999;
    }

    a.delete {
      color: mix($red, $text, 70%);

      &:hover {
        color: $red;
      }
    }
  }

  .jq-dropdown.jq-dropdown-tip::before {
    border-bottom-color: #999;
  }
}

// hamburgerses, that only appear on mobile
.hamburger-opener, .hamburger-closer {
  font-weight: bold;
  color: $white;
  cursor: pointer;
  display: none;

  @media (max-width: $mobile) {
    display: inline-block;
  }
}

.hamburger-target {
  @media (max-width: $mobile) {
    &.hidden {
      display: none !important;
    }
  }
}

