@mixin tooltipize($color: $text) {
  text-decoration: underline;
  // Would love to use dotted styles here, but it only works on Firefox for now,
  // so using a transparent underline will suffice
  text-decoration-color: transparentize($color, 0.66);
}

@mixin accordian-area($background: mix($background, $quiet-border), $border: $quiet-border) {
  padding: 5px;
  background: $background;
  border: thin solid $border;
  box-sizing: border-box;
}

@mixin buttonize($background: $normal-button, $color: $normal-button-text, $extra-darken-border: 0%) {
  background: $background;
  border-color: darken($background, 10% + $extra-darken-border);
  color: $color;
  font-family: $alt-font-family;

  &:hover {
    background: darken($background, 10%);
    color: $color;
  }

  &:active {
    border-color: darken($background, 20%);
  }

  small {
    color: mix($background, $color);
  }

  &.disabled, &[disabled] {
    background: none;
    cursor: initial;
    border-color: darken(transparentize($background, 0.5), 10% + $extra-darken-border);
    color: darken(transparentize($background, 0.5), 10% + $extra-darken-border);

    transition: all 0.1s;  // would be nice to put this in the parent button, but since the hover removes the gradient, it ends up flashing on Firefox

    &:hover {
      background: none;
    }
    &:active {
      padding: $button-top $button-side;
    }
    small {
      color: transparentize($color, 0.5);
    }
  }
}

@mixin unbuttonize() {
  background: initial;
  border: 0;

  &:active {
    background: rgba(0,0,0,0.05);
  }
}

@mixin max-public-width($medium-padding: 1em) {
  max-width: ($public-width * 0.95);
  margin: 0 auto;

  @media (max-width: $public-width) {
    max-width: 100%;
    margin: 0 $medium-padding;
  }

  @media (max-width: $mobile) {
    max-width: 100%;
    margin: 0 0;
  }
}

@mixin max-public-header-width($medium-padding: 1em) {
  max-width: ($public-header-width * 0.95);
  margin: 0 auto;

  @media (max-width: $public-header-width) {
    max-width: 100%;
    margin: 0 $medium-padding;
  }

  @media (max-width: $mobile) {
    max-width: 100%;
    margin: 0 0;
  }
}

@mixin breadcrumb-icon($size: 16px, $background-image: svg-load('images/breadcrumb-gray.svg'), $vertical-align: middle) {
  &:after {
    display: inline-block;
    content: '';
    width: $size;
    height: $size;
    margin: 2px;
    background-size: ($size / 2);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: $background-image;
    vertical-align: $vertical-align;
  }
}

@mixin partial-underline($color: $white, $weight: thin) {
  text-decoration: none;
  border-bottom: $weight solid $color;
}

@mixin no-partial-underline() {
  text-decoration: none;
  border-bottom: thin solid transparent;
}

@mixin feature-column-icons($size: 40px) {
  text-align: center;
  font-family: $alt-font-family;
  font-weight: $alt-font-weight-medium;
  font-size: $font-size * 0.8;
  line-height: $line-height * 0.8;
  color: $dark-blue;
  height: $size * 3.75;
  margin: 1em 2em;

  > .icon {
    display: block;
    width: 100%;
    height: $size * 2;
    background-repeat: no-repeat;
    background-size: $size;
    background-position: 50% 50%;
  }
}

@mixin bubble-feature-column-icons($size: 70px) {
  text-align: center;
  font-size: $font-size * 0.9;
  line-height: $line-height * 0.8;
  height: $size * 5;

  @media (max-width: $mobile) {
    height: $size * 3.5;
  }

  margin: 1em 2em;

  @media (max-width: $public-width) {
    margin: 0.5em 0.5em;
  }

  h2 {
    font-family: $alt-font-family;
    font-weight: $alt-font-weight-medium;
    font-size: $font-size * 1;
    line-height: $line-height * 1;
    color: $dark-blue;
  }

  > .icon {
    display: block;
    width: 100%;
    height: $size * 2;
    background-repeat: no-repeat;
    background-size: $size;
    background-position: 50% 50%;
  }
}

@mixin slider-toggle-button($slider-size: 11px, $container-height: 32px) {
  label {
    $slider-left-padding: 15px + ($slider-size * 1) + (($container-height - $slider-size) * 0.75);

    box-sizing: border-box;
    position: relative;
    height: $container-height;
    padding: $button-top ($button-top + ($slider-left-padding / 4)) $button-top $slider-left-padding;
    line-height: ($container-height / 2) + ($slider-size * 0.7);

    &::before {
      position: absolute;
      background-color: mix($quiet-text, $background, 50%);
      width: ($slider-size * 2) + 2;
      height: $slider-size + 2;
      border-radius: $slider-size;
      display: inline-block;
      content: '';
      top: (($container-height - $slider-size) / 2) - 2px;
      left: ($slider-size * 1) - 1px;
      transition: all 0.2s;
    }

    &::after {
      position: absolute;
      background-color: $quiet-text;
      width: $slider-size;
      height: $slider-size;
      display: inline-block;
      content: '';
      top: (($container-height - $slider-size) / 2) - 1px;
      left: ($slider-size * 1);
      border-radius: $slider-size;
      transition: all 0.2s;
    }

    &:active {
      padding-left: $slider-left-padding + $button-press;
    }
  }

  input[type=checkbox]:checked + label {
    &::before {
      background-color: $quiet-text;
    }

    &::after {
      background-color: $white;
      left: ($slider-size * 2);
    }
  }
}
