.currency[data-code] {
  color: $text;
  padding-left: 16px + 6px;
  min-height: 16px;
  background-repeat: no-repeat;
  background-position: left 30%;
  background-size: 16px;
  background-image: url('/images/currencies/generic.svg');

  > small {
    color: transparentize($text, 0.5);
  }
}

a.currency {
  color: $link;
  > .currency[data-code] {
    color: $link;
  }
}

big, h1, h2 {
  .currency[data-code] {
    padding-left: 22px + 8px;
    min-height: 22px;
    background-size: 22px;
  }
}

span.toggle-button input[type=checkbox]:checked + label .currency {
  color: $ok-button-text;
}

.currency[data-code=ada] {
  background-image: url('/images/currencies/cardano.svg');
}

.currency[data-code=bnt] {
  background-image: url('/images/currencies/bnt.png');
}

.currency[data-code=btc] {
  background-image: url('/images/currencies/bitcoin.svg');
}

.currency[data-code=bch] {
  background-image: url('/images/currencies/bitcoin-cash.png');
  background-position: 0 45%;
}

.currency[data-code=dash] {
  background-image: url('/images/currencies/dash.svg');
}

.currency[data-code=doge] {
  font-family: 'Comic Sans MS', $font-family;
  font-size: 90%;
  background-image: url('/images/currencies/dogecoin.svg');
}

.currency[data-code=dgb] {
  background-image: url('/images/currencies/digibyte.svg');
}

.currency[data-code=eos] {
  background-image: url('/images/currencies/eos.svg');
}

.currency[data-code=eth] {
  background-image: url('/images/currencies/ethereum.svg');
  background-size: 16px * (6/8);
  background-position: 3px 30%;
}

.currency[data-code=etc] {
  background-image: url('/images/currencies/ethereum-classic.svg');
  background-size: 16px * (6/8);
  background-position: 3px 30%;
}

.currency[data-code=iota] {
  background-image: url('/images/currencies/iota.svg');
}

.currency[data-code=kmd] {
  background-image: url('/images/currencies/komodo.svg');
}

.currency[data-code=lsk] {
  background-image: url('/images/currencies/lisk.png');
}

.currency[data-code=ltc] {
  background-image: url('/images/currencies/litecoin.svg');
}

.currency[data-code=neo] {
  background-image: url('/images/currencies/neo.svg');
}

.currency[data-code=xlm] {
  background-image: url('/images/currencies/stellar-rocket.png');
  background-size: 16px * 1.4;
}

.currency[data-code=xrp] {
  background-image: url('/images/currencies/ripple.svg');
}

big, h1, h2 {
  .currency[data-code=eth] {
    background-size: 22px * (6/8);
  }
}

// fiat currencies hack
$fiat-currency-color: darken($blue-gray, 20%);
$fiat-currencies: 'usd', 'eur', 'aud', 'nzd', 'gbp', 'cad', 'hkd', 'cny', 'jpy', 'chf', 'thb', 'zar';
$fiat-ratio: (12 / 16);

@each $currency in $fiat-currencies {
  .currency[data-code=#{$currency}] {
    padding-left: 0;
    background: none;

    &::before {
      font-size: 10px;
      line-height: 16px * (13/16);
      display: inline-block;
      text-align: center;
      vertical-align: 20%;
      width: 16px;
      height: 16px * $fiat-ratio;
      border: 1px outset transparentize($fiat-currency-color, 0.5);
      font-weight: $font-weight;
      background: mix($white, $fiat-currency-color, 80%);
      color: $fiat-currency-color;
      margin-right: 4px;
    }
  }

  big, h1, h2 {
    .currency[data-code=#{$currency}] {
      padding-left: 0;

      &::before {
        font-size: 15px;
        line-height: 24px * (13/16);
        width: 24px;
        height: 24px * $fiat-ratio;
        margin-top: 0;
        vertical-align: 20%;
        margin-right: 8px;
      }
    }
  }
}

.currency[data-code=usd], .currency[data-code=nzd], .currency[data-code=aud], .currency[data-code=cad], .currency[data-code=hkd] {
  &::before {
    content: '$';
  }
}

.currency[data-code=eur]::before {
  content: '\20ac';
}

.currency[data-code=gbp]::before {
  content: '\a3';
}

.currency[data-code=cny]::before {
  content: '¥';
}

.currency[data-code=jpy]::before {
  content: '¥';
}

.currency[data-code=chf]::before {
  content: 'Fr.';
}

.currency[data-code=thb]::before {
  content: '฿';
}

.currency[data-code=zar]::before {
  content: 'R';
}

.fullpage-summary.currency {
  .exchange-pairs {
    header.top {
      margin-top: 3em * $whitespace;
    }
  }

  table.statistics {
    .accordian {
      > header {
        padding-top: 1px;
        margin-bottom: -2px;
      }

      > aside {
        > ul {
          @include accordian-area();
          margin: 0;
          padding: 10px;

          > li {
            list-style: none;
            + li {
              padding-top: 2px;
            }

            .explorer[data-explorer] {
              padding-left: 20px + 4px;
            }
          }
        }
      }
    }
  }

  > footer {
    margin: 1em * $whitespace 0;
  }
}

.fullpage-summary.currency-api {
  .api {
    margin: 0;
    padding: 1em * $whitespace;
    border: thin solid $quiet-border;
    background: $background;

    &:nth-of-type(odd) {
      background: mix($background, $quiet-border);
    }

    + .api {
      border-top: 0;
    }

    > h2 {
      background: url('/images/tick.svg') 0 0 no-repeat;
      background-size: 0.9em;
      padding-left: 1.3em;
    }

    > h2 > small {
      font-size: 50%;
      font-weight: $font-weight;
      display: inline-block;
      padding: 0.12em 0.5em;
      margin: 0 1em;
      vertical-align: middle;
      border: thin solid $quiet-border;
      border-radius: 5px;
    }

    &.public > h2 > small {
      background: mix($background, $green);
      color: darken($green, 20%);
      border-color: darken($green, 20%);
    }

    &.private > h2 > small {
      background: mix($background, $blue);
      color: darken($blue, 20%);
      border-color: darken($blue, 20%);
    }

    > h3 {
      font-weight: $font-weight;
      background: $white;
      border: thin solid $quiet-border;
      padding: 0.25em 0.5em;
    }
  }
}

span.exchange-rate {
  &:after {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-left: 6px;
    background-size: cover;
    vertical-align: 0%;
  }

  &.increase {
    color: $green;
    text-decoration-color: transparentize($green, 0.3);
    &:after {
      background-image: url('/images/arrow-up.svg');
    }
  }
  &.decrease {
    color: $red;
    text-decoration-color: transparentize($red, 0.3);
    &:after {
      background-image: url('/images/arrow-down.svg');
    }
  }
  &.no-change {
    color: $gray;
    text-decoration-color: transparentize($gray, 0.3);
    &:after {
      display: none;
    }
  }
}

ul.currency-support {
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    display: inline-block;
    background: url('/images/tick.svg') 0 0 no-repeat;
    background-size: 16px;

    width: 16px;
    height: 16px;
    text-indent: 9000em;
    overflow: hidden;
    margin: 0 5px;
    padding: 0;
    opacity: 0.8;

    &:hover {
      opacity: 1;
      transition: all 0.2s;
    }

    &.support-rates {
      // TODO support multiple icons
      // background-image: url('/images/add-currency.svg');
    }

    &.support-conversion {
      background-image: url('/images/exchange.svg');
    }

    &.support-balances {
      background-image: url('/images/stacked-chart.svg');
    }

    &.support-transactions {
      background-image: url('/images/line-chart.svg');
    }

    &.support-xpub {
      background-image: url('/images/link-blue-multiple.svg');
    }
  }
}
