> section.login-screen {
  padding: 1.5em * $whitespace;
  @media (max-width: $mobile) {
    padding: (0.5em * $whitespace) (1em * $whitespace);
  }

  $login-panel-height: 11.5em;

  section.login-panel {
    height: $login-panel-height;
  }

  section.column-or {
    margin-top: 1em;
    margin-bottom: 1em;
    height: $login-panel-height + 1em;
    border-left: thin solid $line;
    box-sizing: border-box;

    span {
      display: block;
      margin: ($login-panel-height * 0.45) 0;
      padding: 0.2em;
      position: relative;
      background: $fullscreen-dialog-background;
      width: 1em;
      left: -0.5em + -0.25em;
      text-align: center;
      line-height: $line-height * 0.8;
      color: $line;
    }

    @media (max-width: $mobile) {
      height: 1em;
      margin: 0 0;
      border-left: 0;
      border-bottom: thin solid $line;

      span {
        margin: 0 0;
        padding: 0.2em;
        left: 47.5%;
      }
    }
  }

  section.options {
    margin: (1em * $whitespace) auto;
    margin-bottom: 0;
    width: 90%;
    text-align: center;

    .row {
      display: block;

      > label {
        display: block;
        text-align: left;
      }

      > table {
        margin: 0 auto;

        tr + tr {
          > th, > td {
            padding-top: (0.5em * $whitespace);
          }
        }

        th {
          white-space: nowrap;
        }

        td {
          vertical-align: top;
        }

        &.accept-table {
          td {
            text-align: left;
          }
        }

        &.login-table {
          td {
            text-align: center;

            .forgot-password {
              display: block;
              clear: both;
              font-size: 75%;
            }
          }

          input {
            font-size: 110%;
            line-height: $line-height * 1.1;
            width: 100%;
          }
        }
      }
    }

    .login-method {
      padding-left: 18px + 6px;
      background-size: 18px;
      background-position: left 40%;
      background-repeat: no-repeat;

      &.google {
        background-image: svg-load('images/login-methods/google.svg');
      }

      &.facebook {
        background-image: svg-load('images/login-methods/facebook.svg');
      }

      &.github {
        background-image: url('/images/login-methods/github.png');
      }
    }

    button, a.button {
      width: 8em;
      margin: 0.5em;
      font-size: 100%;
    }
  }

  button, a.button {
    @include buttonize(mix($normal-button, darken($fullscreen-dialog-background, 10%)), $normal-button-text);
  }

  > footer {
    margin-top: 1em;
    color: $quiet-text;
    font-size: 90%;

    @media (max-width: $mobile) {
      text-align: center;
    }

    button, a.button {
      text-align: center;
      margin-left: 0.75em;

      @media (max-width: $mobile) {
        width: 9em;
        margin: 0 auto;
        display: block;
      }
    }
  }
}

> section.login, > section.reset-password {
  table.login-table th {
    display: none;  // we have the labels for accessibility
  }

  section.options {
    width: 90%;
  }
}

> section.security {
  text-align: center;
  margin: (1em * $whitespace);

  > .comodo {
    max-width: 100px;
  }
}

> section.two-factor {
  section.two-factor-code {
    margin: 1em * $whitespace auto;
    text-align: center;

    input[type=submit] {
      vertical-align: text-bottom;
      margin-left: 5px;
    }

    input[type=text] {
      font-size: 150%;
      text-align: center;
      max-width: 250px;

      @media (max-width: $mobile) {
        max-width: 150px;
      }
    }
  }
}
