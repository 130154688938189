a.app-store-button {
  display: inline-block;
  vertical-align: middle;
  width: 135px * 1.2;
  height: 40px * 1.2;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  &.google {
    background-size: 135px * 1.2;
    background-image: url('https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png');
  }

  &.apple {
    background-size: 135px * 1.05;
    background-image: url('https://linkmaker.itunes.apple.com/assets/shared/badges/en-us/appstore-lrg.svg');
  }

  img {
    max-width: 160px;
  }
}

html.email {
  a.app-store-button.apple {
    // Gmail can't render .svg
    background-image: url('https://linkmaker.itunes.apple.com/assets/shared/badges/en-us/appstore-lrg.png');
  }
}
