// jCarousel: These are the minimum settings necessary.
/*
This is the visible area of you carousel.
Set a width here to define how much items are visible.
The width can be either fixed in px or flexible in %.
Position must be relative!
*/
.carousel {
  position: relative;
  overflow: hidden;
  margin: 0 auto;

  // default carousel width
  width: $mobile;

  @media (max-width: $mobile) {
    width: $mobile * 0.9;
  }
}

.carousel.not-active {
  width: auto;
}

/*
This is the container of the carousel items.
You must ensure that the position is relative or absolute and
that the width is big enough to contain all items.
*/
.carousel > div {
  width: 10000em;
  position: relative;

  /* Optional, required in this case since it's a <ul> element */
  list-style: none;
  margin: 0;
  padding: 0;
}

/*
These are the item elements. jCarousel works best, if the items
have a fixed width and height (but it's not required).
*/
.carousel > div > div {
  /* Required only for block elements like <li>'s */
  float: left;

  // default carousel width, needs to be == the .carousel width
  width: $mobile;
  @media (max-width: $mobile) {
    width: $mobile * 0.9;
  }
}

// -- custom extensions --

.carousel {
  > div {
    img {
      max-width: 100%;
    }
  }

  button.next, button.prev {
    color: $black;
    position: absolute;
    top: 50%;
    z-index: 1;
  }

  button.prev {
    left: 0;
  }

  button.next {
    right: 0;
  }

  &.not-active {
    > div {
      width: auto;
    }
  }
}
