.historical-data {
  .columns {
    .column {
      text-align: center;

      a.button {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 75%;

        // TODO refactor this into a mixin
        background-position: 50% 30%;
        background-size: 1.75em * 1.5;
        background-repeat: no-repeat;

        &.exchange {
          background-image: url('/images/exchange.svg');
        }

        &.country {
          background-image: url('/images/globe.svg');
          background-size: 1.75em * 1.2;
        }

        &.currency {
          background-image: url('/images/piggy-bank.svg');
        }

        &.token {
          background-image: url('/images/token.svg');
        }

        &.other {
          background-image: url('/images/tools.svg');
        }

        label {
          display: inline-block;
          padding-top: 4em;
          padding-bottom: 0.5em;
          font-size: 120%;
          cursor: pointer;
        }
      }

      small {
        display: block;
        color: $quiet-text;
        margin: (0.5em * $whitespace) auto;
        width: 80%;
      }
    }
  }
}

.currencies {
  .columns {
    .column {
      a.button {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 75%;

        label {
          display: inline-block;
          padding-top: 0.25em;
          padding-bottom: 0.25em;
          font-size: 100%;
          cursor: pointer;
        }
      }

      small {
        display: block;
        width: 75%;
        margin-top: 0.5em * $whitespace;
        margin-left: auto;
        margin-right: auto;
        color: $quiet-text;
      }
    }
  }

  &.fiat .columns .column a.button.fiat,
  &.cryptocurrencies .columns .column a.button.cryptocurrencies,
  &.tokens .columns .column a.button.tokens,
  &.unknown .columns .column a.button.unknown {
    background-color: darken($normal-button, 10%);
    font-weight: $font-weight-bold;
  }
}

// make rows of table rows more clickable and less web-by
.currency-list, .exchange-list, .exchange-pair-list, .country-list, .portfolio-list {
  table.sortable {
    td:not(.actions):not(.empty) {
      a {
        display: block;
        width: 100%;
        height: 100%;
        text-decoration: none;
        padding: 5px 5px;
        color: $text;

        > small {
          color: $quiet-text;
        }

        > .currency[data-code] {
          color: $text;
        }

        &:hover, &:active {
          text-decoration: underline;
        }

        span.info {
          display: none;
        }
      }

      &.rate {
        a {
          width: 95%;
          margin: auto auto;
        }
      }
    }
  }
}

.exchange-pair, .currency {
  table.statistics {
    tr {
      th, td {
        line-height: 1.5em;
      }

      th {
        white-space: nowrap;
      }
    }
  }
}

table.sortable {
  span.address {
    display: inline-block;
    max-width: 200px;
    @media (max-width: $mobile) {
      max-width: 100px;
    }
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
  }
}
