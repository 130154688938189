@mixin loading-span($image: '/images/rolling.svg', $color: $quiet-text, $background-size-scale: 1.0, $background-position: 0% 15%) {
  $loading-size: 1.2em;

  background: url($image);
  background-size: $loading-size * $background-size-scale;
  background-repeat: no-repeat;
  padding-left: ($loading-size * 1.25);
  background-position: $background-position;
  font-size: 100%;
  color: $color;
}

span.loading {
  @include loading-span();
}
