/**
 * For full-page app views
 */

html {
  body {
    div.fullpage-layout {
      display: table-cell;
      width: 100%;
      height: 100%;
      min-width: 100%;
      min-height: 100%;

      background: $app-footer;

      > header {
        position: relative;
        background: $app-header;
        color: $app-header-text;
        padding: 5px * $whitespace;
        box-shadow: 0 1px 4px rgba(0,0,0,0.5);
        z-index: 10;
      }

      > nav {
        position: relative;
        background: $app-navigation;
        color: $app-navigation-text;
        padding: 5px * $whitespace;
        box-shadow: 0 1px 4px rgba(0,0,0,0.5);
        font-weight: 300;
        z-index: 9;

        &.without-portfolio {
          height: 0;
        }

        a {
          color: $app-navigation-text;
          @include tooltipize($app-navigation-text);

          &:hover {
            color: brighten($app-navigation-text, 20);
            transition: color 0.3s;
          }
        }

        > .summary, > .ticker {
          overflow: hidden;
          background-color: $app-navigation;
          height: $line-height;

          > a.add-more {
            display: inline-block;
            background-image: svg-load('images/plus.svg');
            background-repeat: no-repeat;
            background-size: contain;
            height: $line-height * 0.55;
            vertical-align: -10%;

            @media (max-width: $mobile) {
              // the + adds extra confusion to the page, doesn't really improve UX
              display: none;
            }
          }

          .currency + .currency {
            padding-left: 0.2em;
          }

          .rate {
            color: mix($app-navigation-text, $app-navigation);
            padding-left: 4px;
            padding-right: 1em * $whitespace;
            text-decoration: none;

            @media (max-width: $very-small) {
              // it'll never fit, sadly
              display: none;
            }

            > span.balance {
              text-decoration: none;

              &.exchange-rate {
                &.increase {
                  color: mix($app-navigation, mix($green, $app-navigation-text), 20%);
                  text-decoration-color: transparentize(mix($app-navigation, mix($green, $app-navigation-text), 20%), 0.3);
                }
                &.decrease {
                  color: mix($app-navigation, mix($red, $app-navigation-text), 20%);
                  text-decoration-color: transparentize(mix($app-navigation, mix($red, $app-navigation-text), 20%), 0.3);
                }
                &.no-change {
                  color: mix($app-navigation, $app-navigation-text);
                  text-decoration-color: transparentize(mix($app-navigation, $app-navigation-text), 0.3);
                }
              }
            }
          }
        }

        > .ticker > a.add-more {
          background-position: 100% 50%;
          padding-right: 1.5em;
        }

        > .summary > a.add-more {
          background-position: 0% 50%;
          padding-left: 1.5em;
        }
      }

      > section {
        background: $background;
        color: $text;
        padding: 10px * $whitespace;
        @media (max-width: $mobile) {
          padding: 5px * $whitespace;
        }
        overflow: auto; // https://www.w3schools.com/css/css_float.asp
      }

      > footer {
        clear: both;
        background: $app-footer;
        color: mix($app-footer-text, $app-footer, 50%);
        padding: 0;
        box-shadow: 0 -1px 4px rgba(0,0,0,0.5);

        a {
          color: mix($app-footer-text, $app-footer, 75%);

          &:hover {
            color: $public-footer-text;
            transition: color 0.3s;
          }
        }
      }

      .fullpage-summary {
        max-width: $mobile * 1.5;
      }

      .fullpage-wide {
        max-width: $mobile * 2;
      }

      .fullpage-summary, .fullpage-wide {
        margin-top: 0.5em * $whitespace;
        margin-bottom: 1.5em * $whitespace;
        @media (max-width: $mobile) {
          margin-top: 0;
          margin-bottom: 0.5em * $whitespace;
        }
        margin-left: auto;
        margin-right: auto;

        table.standard {
          min-width: 60%;
          padding-right: (2em * $whitespace);
          @media (max-width: $mobile) {
            padding-right: 0;
          }
          margin: (1em * $whitespace) auto;
        }

        footer {
          text-align: right;
        }

        > section {
          > p {
            margin: 0.2em;

            + p {
              margin-top: 1em;
            }
          }
        }
      }
    }
  }
}
