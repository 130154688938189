.filterable-page {
  section.accordian {
    > header {
      color: $quiet-text;
      font-size: 90%;

      &::after {
        // since the text is smaller, we move the chevron so it renders better
        vertical-align: -10%;
      }
    }

    > aside {
      > section.filters {
        @include accordian-area();

        .column.filter-field {
          display: block;
          padding-right: 0.5em;
          @media (max-width: $mobile) {
            padding-right: 0;
          }
          &:last-child {
            padding-right: 0;
          }
          box-sizing: border-box;

          label {
            display: block;
            font-weight: $font-weight-bold;
          }

          .select2, input[type=text], input[type=url] {
            width: 100%;
          }
        }
      }
    }
  }
}
