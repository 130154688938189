section.fullscreen.oauth-authorisation {
  section.oauth-information {
    overflow: initial;
    margin: (1em * $whitespace) 0;

    p {
      margin: 0;
    }

    > ul.scopes {
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: center;
      margin: 1em * $whitespace;
      border: 1px solid mix($background, $quiet-text);
      border-radius: 5px;
      background: $background;

      > li {
        margin: 0.5em * $whitespace;
      }
    }
  }

  .actions {
    text-align: center;
    margin-bottom: 1.5em * $whitespace;

    input[type=submit] {
      font-size: 120%;
      width: 60%;
      margin: 0.5em * $whitespace;
    }
  }

  > footer {
    font-size: 80%;
    color: $quiet-text;

    a {
      color: $quiet-text;
    }
  }
}
