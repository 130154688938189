[data-dynamic-ajax] {
  .force-refresh {
    cursor: pointer;
  }

  .dynamic-template {
    display: none;
    &.loading {
      display: block;
    }
  }
}
