section, div {
  &.information, &.notice, &.error, &.warning {
    border: 1px solid;
    max-width: ($mobile * 0.7);
    margin: (1em * $whitespace) auto;
    padding: 1em * $whitespace;
    border-radius: (0.3em * $whitespace);

    transition: 0.4s opacity; // for .closing
    opacity: 1;

    &.closing {
      opacity: 0;
    }

    > ul {
      margin: 0;
      padding: 0;
      list-style: none;

      > li {
        + li {
          margin-top: 0.5em;
        }
      }
    }

    @media (max-width: $mobile) {
      max-width: 90%;
      margin: (1em * $whitespace) (2em * $whitespace);
    }

    .button {
      clear: both;
      display: block !important; // TODO !important until legacy has been removed
      margin: 0 auto;
      margin-top: 1em * $whitespace;
      width: 100px;
      text-align: center;
    }

    .buttons {
      display: block;
      text-align: center;

      .button {
        display: inline-block;

        @media (max-width: $mobile) {
          margin-left: 0;
        }
      }
    }
  }

  &.information {
    border-color: darken($blue, 20%);
    color: darken($blue, 20%);
    background: lighten($blue, 30%);

    b, strong {
      color: darken($blue, 30%);
    }
  }

  &.notice {
    border-color: $green;
    color: darken($green, 10%);
    background: lighten($green, 30%);

    b, strong {
      color: darken($green, 10%);
    }
  }

  &.warning {
    border-color: $orange;
    color: darken($orange, 20%);
    background: lighten($orange, 30%);

    b, strong {
      color: darken($orange, 30%);
    }
  }

  &.error {
    border-color: $red;
    color: $red;
    background: lighten($red, 30%);

    b, strong {
      color: darken($red, 10%);
    }
  }
}
