html body section.welcome, html body .fullpage-summary {
  header.top, header.bottom {
    &.top {
      margin-top: 4em;
    }

    &.mini {
      margin-top: 0;
    }

    h1, h2, h3, h4, p, aside {
      max-width: $public-width * 0.8;
      margin: 0 auto;
    }

    h1 {
      margin: 1em auto 1.25em;
      text-align: center;
      color: $dark-blue;

      font-family: $alt-font-family;
      font-weight: $alt-font-weight-bold;
      font-size: 45px;
      line-height: $line-height * 1.8;

      &.ready-title {
        margin: 1em auto 0.5em;
      }

      + h3 {
        margin-top: -1em;
      }

      @media (max-width: $mobile) {
        font-size: 45px * 0.8;
        line-height: $line-height * 1.8 * 0.8;

        margin: (1em * 0.5) auto (1.25em * 0.5);
      }

      &.smaller {
        font-size: 40px;

        @media (max-width: $mobile) {
          font-size: 40px * 0.8;
        }
      }

      a {
        color: $dark-blue;
        &:hover, &:active {
          color: mix($dark-blue, $black, 20%);
        }
      }
    }

    h2 {
      margin: 1em auto;
      text-align: center;
      color: $blue;

      font-family: $alt-font-family;
      font-weight: $alt-font-weight-medium;
      font-size: 30px;

      &.all-plans-include {
        margin-bottom: 55px;
      }

      @media (max-width: $mobile) {
        font-size: 30px * 0.8;
      }

      a {
        color: $blue;
        &:hover, &:active {
          color: mix($blue, $dark-blue, 20%);
        }
      }
    }

    h3 {
      margin: 1.5em auto;
      font-size: 1em;
      text-align: center;
      font-weight: $font-weight;
      color: $quiet-text;

      @media (max-width: $mobile) {
        margin: 1.5em auto;
      }
    }

    p {
      text-align: center;
      margin: 1em auto;

      + p {
        margin-top: 1.5em;
      }
    }

    aside {
      text-align: center;
      margin: 1em auto;
      font-size: 80%;
    }

    &:nth-of-type(1) {
      margin-top: 0;
    }

    h1, h2, h3 {
      strong {
        font-weight: $font-weight-medium;
      }
    }

    section.call-to-action {
      margin: 1em auto;
      text-align: center;
    }
  }

  .top-navigation {
    @media (max-width: $mobile) {
      margin: 0;
      box-shadow: none;
      padding: 0;
      margin-top: 1em * $whitespace;
    }

    .columns {
      @media (max-width: $mobile) {
        text-align: center;
      }

      .column {
        text-align: center;

        a.button {
          @include unbuttonize();

          &:hover {
            text-decoration: underline;
          }
        }

        @media (max-width: $mobile) {
          &.help {
            display: none; // not helpful
          }
        }
      }
    }
  }

  .inline-navigation {
    margin: 0;
    box-shadow: none;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0.5em * $whitespace;

    .columns {
      @media (max-width: $mobile) {
        text-align: center;
      }

      .column {
        text-align: center;

        a.button {
          @include unbuttonize();

          &:hover {
            text-decoration: underline;
          }
        }

        @media (max-width: $mobile) {
          &.help {
            display: none; // not helpful
          }
        }
      }
    }
  }

  aside.call-to-action {
    text-align: center;
  }
}
