$container-border: 1px solid rgba(0,0,0,0.3);
$container-border-subtle: 1px solid rgba(0,0,0,0.15);

section.container, table.sortable {
  margin: (10px * $whitespace) 0;
  border: 0;
  border-radius: 5px;
  padding: (10px * $whitespace);
  box-shadow: 0px 1px 4px rgba(0,0,0,0.2);

  @media (max-width: $mobile) {
    margin: (5px * $whitespace) 0;
  }

  + section.container {
    margin-top: (30px * $whitespace);
  }

  > header, > form > header, > thead {
    position: relative;
    clear: both;
    background: linear-gradient($container-header, darken($container-header, 20%));
    color: $container-header-text;
    margin: (-10px * $whitespace);
    margin-bottom: (10px * $whitespace);
    padding: (10px * $whitespace);
    border-bottom: $container-border;
    box-shadow: 0 3px 3px rgba(0,0,0,0.1);

    h1, h2, h3, h4, h5, h6 {
      margin: 0;
      padding: 0;
      font-weight: $font-weight-medium;

      a, .currency, .currency[data-code] {
        color: $container-header-text;
        text-decoration: none;

        > small {
          color: transparentize($container-header-text, 0.5);
        }
      }

      a {
        .currency, .currency[data-code] {
          &:hover {
            text-decoration: underline;
            @include tooltipize($container-header-text);
          }
        }
      }
    }

    h1 {
      font-size: 175%;
      line-height: 150%;
      margin-left: 0.25em;
      margin-right: 0.25em;
      @media (max-width: $mobile) {
        font-size: 130%;
        line-height: 130%;
      }
    }
    h2, h3, h4, h5, h6 {
      font-size: 100%;
    }

    > aside.actions {
      position: absolute;
      right: 10px * $whitespace;
      top: 10px * $whitespace;
    }
  }

  > footer, > form > footer, > tfoot {
    position: relative;
    clear: both;
    background: $container-footer;
    color: $container-footer-text;
    margin: (-10px * $whitespace);
    margin-top: (10px * $whitespace);
    padding: (5px * $whitespace) (10px * $whitespace);
    border-top: $container-border;
    box-shadow: inset 0 3px 3px rgba(0,0,0,0.1);

    > aside.help {
      position: absolute;
      margin: 10px * $whitespace;
      left: 0;
      bottom: 0;
      text-align: left;
      max-width: 50%;
    }

    .button {
      @include buttonize(mix($white, $normal-button), $normal-button-text);
      margin: 5px * $whitespace 0;

      &.cancel {
        @include buttonize($cancel-button, $cancel-button-text);
      }

      &.ok, &.save {
        @include buttonize($ok-button, $ok-button-text);
      }

      &.primary, &.selected {
        @include buttonize($primary-button, $primary-button-text);
      }

      &.delete {
        @include buttonize($delete-button, $delete-button-text);
      }
    }
  }

  aside.help {
    font-size: 80%;
    line-height: $line-height * 1;
    color: $quiet-text;
    text-shadow: 1px 1px 1px transparentize($white, 0);
    clear: both;
    max-width: $mobile * 0.7;

    @media (max-width: $very-small) {
      display: none; // not enough to display the text, sadly
    }

    a {
      color: $quiet-text;
      &:hover {
        color: darken($quiet-text, 20%);
        transition: color 0.3s;
      }
    }
  }

  &.container.subtle {
    max-width: $mobile;
    margin-left: auto;
    margin-right: auto;
    box-shadow: none;
    border: $container-border-subtle;

    > header, > form > header, > thead {
      background: mix($background, $quiet-text, 90%);
      color: mix($text, $quiet-text, 50%);

      padding-bottom: (5px * $whitespace);
      border-bottom: $container-border-subtle;
      box-shadow: 0 3px 3px rgba(0,0,0,0.05);

      h1, h2, h3, h4, h5, h6 {
        font-weight: $font-weight-medium;
        display: inline-block;
      }

      a {
        color: mix($text, $quiet-text, 50%);
      }
    }

    > footer, > form > footer, > tfoot {
      background: mix($background, $quiet-text, 90%);
      color: mix($text, $quiet-text, 50%);

      border-top: $container-border-subtle;
      box-shadow: inset 0 3px 3px rgba(0,0,0,0.05);
    }

    > p:last-child {
      margin-bottom: 0;
    }

    > aside {
      font-size: 90%;
      margin-bottom: 1em * $whitespace;
    }
  }

  &.accordian {
    header {
      margin-bottom: 0;

      &:hover {
        text-decoration: none;

        h2 {
          text-decoration: underline;
        }
      }

      &.active {
        margin-bottom: 10px * $whitespace;
      }
    }
  }

  > .menu, > aside > .menu {
    width: 100%;
    display: block;
    padding: 1em * $whitespace;
    @media (max-width: $mobile) {
      padding: 0;
    }
    box-sizing: border-box;

    > .option {
      $menu-height: 5.5em * $whitespace;
      $menu-width: $menu-height * 2;

      width: 100%;
      display: block;
      position: relative;
      height: $menu-height;

      > .button {
        clear: both;
        display: inline-block;
        box-sizing: border-box;
        width: $menu-width;
        height: $menu-height;
      }

      > aside {
        display: inline-block;
        box-sizing: border-box;
        vertical-align: middle;
        padding-top: 1em * $whitespace;
        position: absolute;
        left: $menu-width + (1em * $whitespace);
        overflow: hidden;
      }

      + .option {
        margin-top: 1em * $whitespace;
      }
    }
  }
}

section.container {
  > aside.pagination {
    border-top: 0;
    box-shadow: none;
    background: $background;
    margin: (10px * $whitespace) 0;
  }
}

@import "sortable";
