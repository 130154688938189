.action-bar {
  padding-left: 5px * $whitespace;
  padding-right: 5px * $whitespace;

  .action-item {
    display: inline-block;
    font-size: 90%;
    color: $quiet-text;

    + .action-item {
      padding-left: 0.75em * $whitespace;
    }

    > button, > input {
      vertical-align: middle;
    }

    .select2-container, select.dropdown {
      margin-left: 5px;
      margin-right: 5px;
    }

    select.dropdown, .select2-container .select2-selection {
      min-width: 150px;
      @media (max-width: $mobile) {
        min-width: 150px;
      }

      .select-template {
        line-height: 28px * 0.8;
      }
    }

    &.action-item-sums {
      margin-top: 0.5em * $whitespace;
      min-width: 300px;

      @media (max-width: $mobile) {
        min-width: initial;
      }
    }

    // &.float-right {
    //   float: right;
    // }
  }
}
