.wallet-list {
  .feature.tick {
    background: url('/images/tick.svg') 0 45% no-repeat;
    background-size: 0.9em;
    padding-left: 1.3em;

    + .feature.tick {
      margin-left: 1em;
    }
  }
}
